import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Popup } from '../../UI';

import Navigation from './Navigation';

import pushEventOpenGA from '../pushEventOpenGA';

class Menu extends React.Component {
  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    const newOpenState = () => nextProps.isOpen !== this.props.isOpen;
    const newCategories = () =>
      isEmpty(this.props.categories) && !isEmpty(nextProps.categories);
    const newPathSheme = () => nextProps.pathScheme !== this.props.pathScheme;
    const newCurrentPath = () =>
      nextProps.currentPath !== this.props.currentPath;
    const newHeaderHeight = () =>
      nextProps.getHeaderHeight() !== this.props.getHeaderHeight();

    if (
      (nextProps.isOpen &&
        (newOpenState() ||
          newHeaderHeight() ||
          newCategories() ||
          newPathSheme() ||
          newCurrentPath())) ||
      (newOpenState() && !nextProps.isOpen)
    ) {
      return true;
    }

    return false;
  }

  componentDidUpdate() {
    const { isOpen } = this.props;

    pushEventOpenGA(isOpen, 'Desktop Menu', false);
  }

  render() {
    const headerHeight = this.props.getHeaderHeight();

    return (
      <Popup
        isOpen={this.props.isOpen}
        headerHeight={headerHeight}
        width={this.props.width}
        location="left"
        onClose={this.props.onClose}
      >
        <Navigation
          headerHeight={headerHeight}
          categories={this.props.categories}
          apiInstance={this.props.apiInstance}
          pathScheme={this.props.pathScheme}
          currentPath={this.props.currentPath}
          onClose={this.props.onClose}
        />
      </Popup>
    );
  }
}

export default Menu;
