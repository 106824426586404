import { combineReducers } from 'redux';

import cartReducer from './Popups/Cart/reducer';
import popupsReducer from './Popups/reducer';
import apiReducer from './Layout/apiReducer';
import userReducer from './Layout/userReducer';
import modalsReducer from './Modals/reducer';

export default combineReducers({
  cart: cartReducer,
  popups: popupsReducer,
  api: apiReducer,
  user: userReducer,
  modals: modalsReducer,
});
