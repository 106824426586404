import React from 'react';

import Main from './Main';

export default class Footer extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.currentPath !== this.props.currentPath) {
      return true;
    }

    return false;
  }

  render() {
    return <Main {...this.props} />;
  }
}
