import React from 'react';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { Preloader } from '../../UI';
import Login from './Login';
import User from './User';
import Register from './Register';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;

class PersonalMain extends React.Component {
  state = {
    loading: false,
    tab: 'login',
  };

  shouldComponentUpdate(nextProps, nextState) {
    const loginingUser = isEmpty(this.props.user) && !isEmpty(nextProps.user);
    const updatingUser = !isEmpty(this.props.user) && !isEqual(this.props.user, nextProps.user);

    if (!isEqual(this.state, nextState) || loginingUser || updatingUser) {
      return true;
    }

    return false;
  }

  sendError = (extra, title) => {
    let eventID = false;

    Sentry.withScope((scope) => {
      scope.setExtras(extra);
      scope.setTag('page', 'popup_personal');
      scope.setTag('individual_errors', 'true');
      eventID = Sentry.captureEvent({
        message: title || 'Popup->Personal',
      });
    });

    return eventID;
  };

  onLogout = (token) => {
    if (window.ga) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Personal',
        eventAction: 'Logout',
        eventLabel: 'success',
      });
    }

    this.props.apiInstance
      .get(`/logout/${token}`)
      .then((res) => {
        const { data } = res;

        if (data.result !== true) {
          this.sendError(
            {
              sendData: token,
              response: res,
              responseData: data,
            },
            'Popup Personal Logout Data Error',
          );
        }
      })
      .catch((error) => {
        let data = {};

        if (error.response) {
          data = error.response.data;
        }

        this.sendError(
          {
            sendData: token,
            errorResponse: error.response,
            errorCode: data.error || '',
            errorMessage: data.message || '',
          },
          'Popup Personal Logout Fetch Error',
        );
      });

    this.props.onLogout(token);
  };

  render() {
    return (
      <Wrapper
        ref={(el) => {
          this.props.setScrollableContainer(el);
        }}
      >
        {this.state.loading ? (
          <Preloader />
        ) : (
          <>
            {this.state.tab === 'login' ? (
              <>
                {isEmpty(this.props.user) ? (
                  <Login
                    onLogin={this.props.onLogin}
                    apiInstance={this.props.apiInstance}
                    onChangeTab={tab => this.setState({ tab })}
                    onChangeModal={this.props.onChangeModal}
                  />
                ) : (
                  <User user={this.props.user} onLogout={this.onLogout} />
                )}
              </>
            ) : (
              <Register
                onChangeTab={() => this.setState({ tab: 'login' })}
                onLogin={this.props.onLogin}
                apiInstance={this.props.apiInstance}
                onChangeModal={this.props.onChangeModal}
              />
            )}
          </>
        )}
      </Wrapper>
    );
  }
}

export default connect(
  store => ({ user: store.user }),
  dispatch => ({
    onLogin: (payload) => {
      dispatch({ type: 'ON_LOGIN', payload });
    },
    onLogout: (token) => {
      dispatch({ type: 'ON_LOGOUT', payload: token });
    },
    onChangeModal: (modal) => {
      dispatch({ type: 'CHANGE_MODAL', payload: modal });
    },
  }),
)(PersonalMain);
