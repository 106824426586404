import React from 'react';

import { Links } from '../styled';
import LinkWithIcon from '../LinkWithIcon';

const LinksLeft = (props) => {
  const { isMenuOpen, isPopupOpen, isPersonalOpen, onChangePopup } = props;

  return (
    <Links left>
      <LinkWithIcon
        to="/category/bestsellers"
        isActive={isMenuOpen}
        isPopupOpen={isPopupOpen}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('menu');
        }}
        name="Все товары"
        iconName="Items"
        iconWidth="20px"
        iconMargin="0 7px 0 0"
      />

      <LinkWithIcon
        to="/auth"
        isActive={isPersonalOpen}
        isPopupOpen={isPopupOpen}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('personal');
        }}
        name="Личный кабинет"
        iconName="Ingerprint"
        iconWidth="20px"
        iconMargin="0 7px 0 0"
      />
    </Links>
  );
};

export default LinksLeft;
