import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';

import Slider from './Slider';

const Title = styled.h2`
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;

  @media screen and (min-width: 1200px) {
    font-size: 31px;
    margin-bottom: 50px;
  }
`;

export default class RocketRetail extends React.Component {
  retailRocket = null;

  suggesterID = 'widget';

  constructor() {
    super();

    this.state = {
      cards: null,
    };
  }

  componentDidMount() {
    window.onRenderWidgetRetailRocket = this.onRenderRetailRocket;
  }

  componentWillUnmount() {
    window.onRenderWidgetRetailRocket = null;
  }

  onRenderRetailRocket = () => {
    if (!this.retailRocket) {
      return false;
    }

    const { apiInstance: api } = this.props;

    const cardsByRetailRocket = this.retailRocket.getAttribute(
      'data-rendered-items-ids',
    );

    if (!cardsByRetailRocket || cardsByRetailRocket.length === 0) {
      return false;
    }

    api.get(`/product/${cardsByRetailRocket}`).then(({ data: cards }) => {
      this.setState({ cards });
    });

    return true;
  };

  renderCards = (cards) => (
    <>
      <Title>{this.props.title}</Title>

      <Slider
        cards={cards}
        algorithm={this.props.algorithm}
        suggesterID={this.suggesterID}
        maxItems={this.props.maxItems}
        from={this.props.from}
      />
    </>
  );

  render() {
    return (
      <>
        <Helmet>
          <script>retailrocket.widget.render();</script>
        </Helmet>

        <div
          className="retailrocket retailrocket-widget retailrocket-widget-theme-hermitageshop"
          data-algorithm-type={this.props.algorithmType} // "visitor-category-interest"
          data-algorithm={this.props.algorithm} // "popular"
          data-algorithm-argument={this.props.algorithmArgument} // for example "product-id"
          data-template-param-number-of-items={this.props.maxItems}
          data-template-param-item-image-width="370"
          data-template-param-item-image-height="370"
          data-textoverflowhidden="false"
          data-retailrocket-morebutton="false"
          data-retailrocket-buybutton="false"
          data-on-post-render="window.onRenderWidgetRetailRocket()"
          ref={(el) => (this.retailRocket = el)}
        />

        <div>{this.state.cards ? this.renderCards(this.state.cards) : ''}</div>
      </>
    );
  }
}

RocketRetail.defaultProps = {
  maxItems: 10,
};
