import React from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { Grid } from 'react-flexbox-grid';
import styled from 'styled-components/macro';
import forEach from 'lodash/forEach';

import { getMainCategory, validateProductCards } from '../../Utils';

import { arrowHorizontalScroll as arrow } from '../icons';

import ProductCard from '../ProductCard';

import './styles.css';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const Arrow = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 130px;
  padding-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isDisable ? 0 : 1)};
  cursor: ${(props) => (props.isDisable ? 'default' : 'pointer')};
  transition: all 0.18s;

  &:before {
    content: '';
    position: relative;
    display: block;
    width: 17px;
    height: 40px;
    background-image: url(${arrow});
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 50% 50%;
    transition: all 0.18s ease;
  }

  &:hover {
    &:before {
      transform: translate(50%, 0);
    }
  }
`;

const ArrowNext = styled(Arrow)`
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(250, 250, 250, 1) 100%
  );
`;

const ArrowPrev = styled(Arrow)`
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(250, 250, 250, 1) 100%
  );
  transform: rotate(180deg);
`;

function SliderArrow(props) {
  const { className, style, onClick } = props;

  if (props.prev) {
    return <ArrowPrev onClick={onClick} isDisable={props.currentSlide === 0} />;
  }

  if (props.next) {
    return (
      <ArrowNext
        onClick={onClick}
        isDisable={props.currentSlide === props.slideCount - 3}
      />
    );
  }
}

const Slide = styled.div`
  outline: none;
`;

const sliderSettings = {
  infinite: true,
  speed: 400,
  arrows: false,
  nextArrow: <SliderArrow next />,
  prevArrow: <SliderArrow prev />,
  variableWidth: true,
  dots: true,
  autoplay: true,
  initialSlide: 0,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        // centerMode: true,
      },
    },
  ],
};

class SliderRetailRocket extends React.Component {
  slider = null;

  componentDidMount() {}

  onAddCart = (product) => {
    this.props.onAddProduct(product);

    this.props.onChangePopup({ cart: { isOpen: true } });
  };

  onClickSlide = (e) => {
    const slide = e.target.closest('.slick-slide');

    const isActiveSlide = slide.classList.contains('slick-active');

    if (!isActiveSlide && this.slider) {
      const currentSlide = this.slider.innerSlider.list.parentNode.parentNode.querySelector(
        '.retailRocket-slider > .slick-list > .slick-track > .slick-current',
      );

      const activeSlideIndex = parseInt(
        currentSlide.getAttribute('data-index'),
        10,
      );

      const clickedSlideIndex = parseInt(slide.getAttribute('data-index'), 10);

      if (activeSlideIndex > clickedSlideIndex) {
        this.slider.slickPrev();
      } else {
        this.slider.slickNext();
      }
    }
  };

  render() {
    const { cards } = validateProductCards(this.props.cards);

    const listNameGA = `RetailRocket: algorithm ${this.props.algorithm} | from ${this.props.from}`;

    return (
      <Wrapper>
        <Grid>
          <Slider
            ref={(slider) => (this.slider = slider)}
            {...sliderSettings}
            className="retailRocket-slider"
            onReInit={this.onReInit}
          >
            {cards.map((card) => (
              <Slide onClick={this.onClickSlide}>
                <ProductCard
                  gallery={card.images}
                  options={card.options}
                  price={parseFloat(card.price)}
                  discount={parseFloat(card.discount)}
                  discountPercent={parseFloat(card.discountpercent)}
                  id={card.id}
                  startPrice={parseFloat(card.startPrice)}
                  mainCategory={getMainCategory(card.categories)}
                  onAddCart={this.onAddCart}
                  instock={card.instock}
                  from={listNameGA}
                  card={card}
                  width="370px"
                  size="similar"
                  key={`retailRocket_slider_productid_${card.id}`}
                  onClickToViewProduct={(productGA) => {
                    if (window.ga) {
                      window.ga('ec:addProduct', productGA);

                      window.ga('ec:setAction', 'click', {
                        list: listNameGA,
                      });

                      window.ga('send', {
                        hitType: 'event',
                        eventCategory: 'RetailRocket Product Card',
                        eventAction: 'click',
                        eventLabel: this.props.from,
                      });
                    }

                    window.retailrocket.widget.click(
                      card.id,
                      this.props.suggesterID,
                      this.props.algorithm,
                    );
                  }}
                  onAddToCart={() =>
                    window.retailrocket._widgetAddToBasket(card.id, '')
                  }
                  typeRender="retailrocket"
                >
                  {card.name}
                </ProductCard>
              </Slide>
            ))}
          </Slider>
        </Grid>
      </Wrapper>
    );
  }
}

export default connect(
  (store) => ({ api: store.api }),
  (dispatch) => ({
    onAddProduct: (product) => {
      dispatch({ type: 'ADD_TO_CART', payload: product });
    },
    onChangePopup: (popup) => {
      dispatch({ type: 'CHANGE_POPUP', payload: popup });
    },
  }),
)(SliderRetailRocket);
