import React from 'react';
import styled from 'styled-components/macro';
import qs from 'qs';
import * as Sentry from '@sentry/browser';

import {
  Button, Modal, Input, MainTitle,
} from '../UI';

import { circleSuccess } from '../UI/icons';

import { validateEmail } from '../Utils';

const Title = styled(MainTitle)`
  text-align: center;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 10px;
  font-size: 10px;
  font-weight: 500;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;

  @media screen and (min-width: 1200px) {
    margin-top: 60px;
  }
`;

const Content = styled.div`
  margin-top: 30px;

  @media screen and (min-width: 1200px) {
    margin-top: 50px;
  }
`;

const Success = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SuccessTitle = styled(MainTitle)`
  margin-top: 20px;
  margin-bottom: 10px;
`;

const SuccessDescription = styled.p`
  font-size: 10px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-align: center;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;

const Icon = styled.img`
  max-width: 60px;
  margin-top: 25px;

  @media screen and (min-width: 1200px) {
    max-width: auto;
    margin-top: 0;
  }
`;

export default class ForgetPassword extends React.Component {
  state = {
    email: '',
    success: false,
    loading: false,
  };

  restorePassword = () => {
    if (this.state.loading) {
      return false;
    }

    if (!this.state.email || !validateEmail(this.state.email)) {
      alert('Заполните поле Email');
      return false;
    }

    this.setState({ loading: true }, () => {
      const sendData = qs.stringify({ email: this.state.email });

      this.props.apiInstance
        .post('/resetpassword', sendData, {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(({ data }) => {
          if (data && data.result && Boolean(data.result) === true) {
            if (window.ga) {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'Personal',
                eventAction: 'Forget Password',
                eventLabel: 'success',
              });
            }

            this.setState({ success: true, loading: false });
          }
        })
        .catch((error) => {
          let data = {};

          if (error.response) {
            data = error.response.data;
          }

          if (data.error === '0x0000404') {
            alert('Пользователя с таким E-Mail не найдено');
          } else {
            if (window.jivo_api) {
              window.jivo_api.open();
            }

            const sentryID = this.sendError(
              {
                sendData,
                errorResponse: error.response,
                errorCode: data.error || '',
                errorMessage: data.message || '',
              },
              'Forget Password Modal Fetch Error',
            );

            alert(
              `Ошибка #10012 | ${sentryID}. Напишите нам в чат и сообщите номера ошибок.`,
            );
          }

          console.log(error);
        });
    });

    return true;
  };

  sendError = (extra, title) => {
    let eventID = false;

    Sentry.withScope((scope) => {
      scope.setExtras(extra);
      scope.setTag('page', 'forget_password_modal');
      scope.setTag('individual_errors', 'true');
      eventID = Sentry.captureEvent({
        message: title || 'Modal->ForgetPassword',
      });
    });

    return eventID;
  };

  renderForm = () => (
    <>
      <Title>Забыли пароль?</Title>
      <Description>
        Если вы не помните свой пароль, введите адрес электронной почты, и мы
        отправим письмо с инструкциями по восстановлению
      </Description>

      <Content>
        <Input
          label="Email"
          onChange={target => this.setState({ email: target.value })}
        />
      </Content>

      <ButtonWrap>
        <Button
          padding="17px 70px"
          paddingMobile="12px 35px"
          onClick={this.restorePassword}
        >
          Восстановить пароль
        </Button>
      </ButtonWrap>
    </>
  );

  renderSuccess = () => (
    <Success>
      <Icon src={circleSuccess} />
      <SuccessTitle>Успешно!</SuccessTitle>
      <SuccessDescription>
        Письмо с инструкциями по восстановлению пароля было отправлено на
        указанный email
      </SuccessDescription>
    </Success>
  );

  render() {
    const { isOpen, onClose, openingPoint } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose} openingPoint={openingPoint}>
        <>{this.state.success ? this.renderSuccess() : this.renderForm()}</>
      </Modal>
    );
  }
}
