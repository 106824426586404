import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import Menu from './Menu';
import Search from './Search';
import Cart from './Cart';
import Visitmuseum from './Visitmuseum';
import Personal from './Personal';
import Mobile from './Mobile';

class Popups extends React.Component {
  componentDidMount() {
    if (isEmpty(this.props.categories)) {
      this.props.apiInstance
        .get('/categories/0')
        .then(({ data }) => this.props.onFetchedCategories(data));
    }
  }

  onChangePopup = (name) => {
    if (name && !this.props.popups[name].isOpen) {
      this.props.onChangePopup({
        [name]: {
          isOpen: true,
        },
      });
    } else {
      this.props.onChangePopup(false);
    }
  };

  render() {
    // const { popups } = this.props;

    const {
      popups: {
        search,
        menu,
        cart,
        visitmuseum,
        personal,
        // currentShift,
        mobile,
      },
      api: { categories },
      currentPath,
      pathScheme,
      getHeaderHeight,
      // isPopupOpened,
    } = this.props;

    return (
      <>
        <Mobile
          getHeaderHeight={getHeaderHeight}
          width={mobile.size.width}
          onChangePopup={this.onChangePopup}
          apiInstance={this.props.apiInstance}
          onClose={() => this.onChangePopup()}
          isOpen={mobile.isOpen}
          categories={categories}
          pathScheme={pathScheme}
          currentPath={currentPath}
        />

        <Search
          isOpen={search.isOpen}
          onClose={() => search.isOpen && this.onChangePopup()}
          height={search.size.height}
        />

        <Menu
          isOpen={menu.isOpen}
          onClose={() => this.onChangePopup()}
          getHeaderHeight={getHeaderHeight}
          width={menu.size.width}
          categories={categories}
          apiInstance={this.props.apiInstance}
          pathScheme={pathScheme}
          currentPath={currentPath}
        />

        <Cart
          isOpen={cart.isOpen}
          onClose={() => this.onChangePopup()}
          getHeaderHeight={getHeaderHeight}
          width={cart.size.width}
          currentPath={currentPath}
        />

        <Visitmuseum
          isOpen={visitmuseum.isOpen}
          onClose={() => this.onChangePopup()}
          getHeaderHeight={getHeaderHeight}
          width={visitmuseum.size.width}
        />

        <Personal
          isOpen={personal.isOpen}
          onClose={() => this.onChangePopup()}
          getHeaderHeight={getHeaderHeight}
          width={personal.size.width}
          apiInstance={this.props.apiInstance}
        />
      </>
    );
  }
}

export default connect(
  (store) => ({ popups: store.popups, api: store.api }),
  (dispatch) => ({
    onChangePopup: (popup) => {
      dispatch({ type: 'CHANGE_POPUP', payload: popup });
    },
    onFetchedCategories: (categories) => {
      dispatch({ type: 'FETCHED_CATEGORIES', payload: categories });
    },
  }),
)(Popups);
