import React from 'react';
import styled, { css } from 'styled-components/macro';

const Svg = styled.svg`
  margin: ${props => (props.margin ? props.margin : '')};
  width: 20px;

  @media screen and (min-width: 1200px) {
    width: ${props => props.width};
  }
`;

const Path = styled.path`
  fill: none;
  stroke: #000000;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
`;

const AnimatePath = styled(Path)`
  stroke-dasharray: ${props => props.dashArray};
  stroke-dashoffset: ${props => props.dashOffset};
  stroke: #e71d85;
  transition: stroke-dashoffset 0.7s;

  ${props => props.isHover
    && css`
      stroke-dashoffset: ${propsHovered => propsHovered.dashOffsetOnHover};
    `};
`;

const Line = styled.line`
  fill: none;
  stroke: #000000;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
`;

const AnimateLine = styled(Line)`
  stroke-dasharray: ${props => props.dashArray};
  stroke-dashoffset: ${props => props.dashOffset};
  stroke: #e71d85;
  transition: stroke-dashoffset 0.3s linear 0.5s;

  ${props => props.isHover
    && css`
      stroke-dashoffset: ${propsHovered => propsHovered.dashOffsetOnHover};
    `};
`;

export default class Items extends React.PureComponent {
  render() {
    return (
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 16.8 17"
        xmlSpace="preserve"
        width={this.props.width}
        margin={this.props.margin}
      >
        <Path
          d="M1.4,7.4c-0.1-0.6,0.4-1.1,1-1.1h12.3c0.6,0,1,0.5,1,1.1l-0.8,7.2c-0.1,0.6-0.6,1.1-1.2,1.1H3.4
	c-0.6,0-1.1-0.5-1.2-1.1L1.4,7.4L1.4,7.4z"
        />
        <AnimatePath
          dashArray="44"
          dashOffset="44"
          dashOffsetOnHover="0"
          isHover={this.props.isHover}
          d="M1.4,7.4c-0.1-0.6,0.4-1.1,1-1.1h12.3c0.6,0,1,0.5,1,1.1l-0.8,7.2c-0.1,0.6-0.6,1.1-1.2,1.1H3.4
	c-0.6,0-1.1-0.5-1.2-1.1L1.4,7.4L1.4,7.4z"
        />

        <Line x1="3.5" y1="3.9" x2="13.5" y2="3.9" />
        <AnimateLine
          dashArray="10"
          dashOffset="10"
          dashOffsetOnHover="20"
          isHover={this.props.isHover}
          x1="3.5"
          y1="3.9"
          x2="13.5"
          y2="3.9"
        />

        <Line x1="5.3" y1="1.4" x2="11.7" y2="1.4" />
        <AnimateLine
          dashArray="7"
          dashOffset="7"
          dashOffsetOnHover="0"
          isHover={this.props.isHover}
          x1="5.3"
          y1="1.4"
          x2="11.7"
          y2="1.4"
        />
      </Svg>
    );
  }
}
