import React from 'react';
import styled from 'styled-components/macro';

import errorSVG from './icons/error2.svg';
import arrowError from './icons/arrowError.svg';

import Title2 from './Title2';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
`;

const Img = styled.img`
  width: 250px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 10px;
`;

const Arrow = styled.img`
  position: relative;
  right: 10px;
  transition: all 0.18s;
`;

const Link = styled.button`
  color: inherit;
  text-decoration: none;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  background: none;
  margin-top: 80px;

  &:hover {
    ${Arrow} {
      right: 15px;
    }
  }
`;

export default (props) => (
  <Wrapper>
    <Img src={errorSVG} />

    <Title2>
      {props.server ? 'Произошла ошибка' : 'Страница не найдена'} :(
    </Title2>

    <Description>
      {props.server
        ? 'Мы уже решаем проблему, попробуйте посмотреть пока другие наши товары'
        : 'Возможно, вы ввели неправильный адрес или ссылка оказалась недействительной'}
    </Description>

    <Link
      onClick={() => {
        if (window.history.length > 1) {
          props.history.goBack();
        } else {
          props.history.push('/');
        }
      }}
    >
      <Arrow src={arrowError} />
      Вернуться назад
    </Link>
  </Wrapper>
);
