import React from 'react';
import styled from 'styled-components/macro';

import MainCategories from './MainCategories';
import SubCategories from './SubCategories';

const Wrapper = styled.div``;

const Inner = styled.div`
  /* position: fixed;
  top: 70px;
  z-index: 20; */
  width: 100%;
`;

export default class DetailMenu extends React.Component {
  componentDidMount() {}

  render() {
    const {
      pathname,
      categories,
      subCategories,
      parentCategory,
      currentSubcategory,
      parentCategorySlug,
    } = this.props;

    return (
      <Wrapper>
        <Inner>
          <MainCategories pathname={pathname} categories={categories} />

          <SubCategories
            pathname={pathname}
            subCategories={subCategories}
            parentCategory={parentCategory}
            currentSubcategory={currentSubcategory}
            parentCategorySlug={parentCategorySlug}
          />
        </Inner>
      </Wrapper>
    );
  }
}
