import React from 'react';
import styled from 'styled-components/macro';

import { NumSlider } from '../../UI';
import { decodeHtmlSpecialChars } from '../../Utils';

import crossSVG from './cross.svg';

const Wrapper = styled.div`
  margin-left: 20px;
  display: flex;
  margin-bottom: 20px;

  @media screen and (min-width: 1200px) {
    margin-left: 40px;
  }
`;

const CrossWrapper = styled.div`
  width: 5%;
`;

const MainInfo = styled.div`
  width: 95%;
  display: flex;
  height: 100%;
  padding-right: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f3f3f3;

  @media screen and (min-width: 1200px) {
    padding-right: 40px;
  }
`;

const Cross = styled.img`
  cursor: pointer;
  max-width: 75%;
  position: relative;
  left: -7px;
  top: -3px;

  @media screen and (min-width: 1200px) {
    max-width: 100%;
    left: initial;
    top: initial;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
`;

const Type = styled.span`
  line-height: 1;
  font-size: 9px;
  font-weight: 600;
  color: #a5a5a5;
  margin-bottom: 5px;

  @media screen and (min-width: 1200px) {
    font-size: 10px;
    margin-bottom: 0;
  }
`;

const Name = styled.span`
  font-size: 13px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    font-weight: 500;
  }
`;

const Nums = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;
`;

const Price = styled.div`
  font-size: 13px;
  font-weight: 700;
  align-self: flex-end;
  text-align: right;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    align-self: center;
  }
`;

const PriceWrap = styled.div`
  min-width: 30%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export default props => (
  <Wrapper>
    {props.allowEdit ? (
      <CrossWrapper>
        <Cross onClick={() => props.onDelete(props.id)} src={crossSVG} />
      </CrossWrapper>
    ) : (
      ''
    )}

    <MainInfo>
      <Info>
        <Type>{props.mainCategory}</Type>
        <Name>{decodeHtmlSpecialChars(props.name)}</Name>
      </Info>

      <PriceWrap>
        <Nums>
          {props.allowEdit ? (
            <NumSlider
              nums={props.quantity}
              onChangeQuantity={props.onChangeQuantity}
              min={1}
              max={props.maxquantity || 2}
            />
          ) : (
            props.quantity
          )}
        </Nums>

        <Price>{`${props.discount || props.price} ₽`}</Price>
      </PriceWrap>
    </MainInfo>
  </Wrapper>
);
