import React from 'react';
import styled from 'styled-components/macro';

import { Link } from '../styled';

import logo from './logoImg.svg';

const LinkLogo = styled(Link)`
  margin-right: 0;

  &:after {
    display: none;
  }
`;

const LogoImg = styled.img`
  flex-basis: auto;
  max-width: 100px;
  transition: all 0.2s linear;

  @media screen and (min-width: 1200px) {
    max-width: 199px;
  }
`;

export default class Logo extends React.Component {
  logoDOM = null;

  componentDidMount() {
    this.props.onRender(this.logoDOM);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <LinkLogo to="/">
        <LogoImg
          src={logo}
          ref={(e) => {
            this.logoDOM = e;
          }}
        />
      </LinkLogo>
    );
  }
}
