import styled, { css } from 'styled-components/macro';
import { Link as RLink } from 'react-router-dom';

import arrow from './arrow.svg';

import {
  DiscountWrapper as DiscountWrapperUI,
  DiscountPercent as DiscountPercentUI,
  DiscountText as DiscountTextUI,
} from '../styled/Discount';

export const PhotoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
`;

export const Slide = styled.div`
  height: 100%;
`;

export const SlideInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Photo = styled.img`
  /* max-width: 120%; */
  max-width: 100%;
  min-height: 100px;
`;

export const Gallery = styled.div`
  width: 100%;
  height: ${(props) => (props.size === 'similar' ? '370px' : '140px')};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #f5f5f5;
  overflow: hidden;
  position: relative;

  @media screen and (min-width: 1200px) {
    height: ${(props) => (props.size === 'similar' ? '370px' : '480px')};
  }

  @media screen and (min-width: 1800px) {
    height: ${(props) => (props.size === 'similar' ? '370px' : '600px')};
  }

  @media screen and (min-width: 2350px) {
    height: ${(props) => (props.size === 'similar' ? '370px' : '800px')};
  }

  @media screen and (min-width: 3500px) {
    height: ${(props) => (props.size === 'similar' ? '370px' : '900px')};
  }
`;

export const Arrow = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 18px;
  width: 54px;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 18px 50%;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    background-position: 25px 50%;
  }
`;

export const ArrowLeft = styled(Arrow)`
  left: 0;
  transform: rotate(180deg) translateX(54px);
  display: ${(props) => (props.isVisible ? 'inherit' : 'none')};
`;

export const ArrowRight = styled(Arrow)`
  right: 0;
  transform: translateX(54px);
  display: ${(props) => (props.isVisible ? 'inherit' : 'none')};
`;

export const GalleryWrapper = styled.div`
  border: 1px solid #ededed;
`;

export const Wrapper = styled.div`
  ${(props) => (props.typeRender === 'retailrocket'
    ? css`
          width: 135px;

          @media screen and (min-width: 1200px) {
            width: 370px;
          }
        `
    : css`
          width: 50%;
          margin-bottom: 20px;

          &:nth-child(2n + 1) {
            ${GalleryWrapper} {
              border-right: none;
            }
          }

          @media screen and (min-width: 1200px) {
            width: 33.33333333%;

            &:nth-child(3n + 1) {
              ${GalleryWrapper} {
                border-right: none;
              }
            }

            &:nth-child(3n + 2) {
              ${GalleryWrapper} {
                border-right: none;
              }
            }
          }
        `)};
`;

export const Name = styled.h4`
  font-size: 13px;
  font-weight: 500;
  display: block;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 0 10px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    padding: 0 15px;
    margin-top: 30px;
  }
`;

export const Price = styled.span`
  display: block;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  transition: all 0.25s;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

export const TextSlider = styled.div`
  margin-top: 7px;
  text-align: center;
  overflow: hidden;
  position: relative;
`;

export const AddToCart = styled.span`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  font-size: 13px;
  font-weight: 700;
  color: #e71d85;
  display: block;
  width: 100%;
  transition: all 0.25s;
  transform: translateY(30px);

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;

export const Link = styled(RLink)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-decoration: none;
  color: inherit;
  overflow: hidden;

  @media screen and (min-width: 1200px) {
    &:hover {
      ${Price} {
        transform: translateY(-30px);
      }

      ${AddToCart} {
        transform: translateY(0px);
      }

      ${ArrowLeft} {
        transform: rotate(180deg) translateX(0);
      }

      ${ArrowRight} {
        transform: translateX(0);
      }
    }
  }
`;

export const DiscountPrice = styled.span`
  color: #e71d85;
  margin-left: 10px;
`;

export const DiscountWrapper = styled(DiscountWrapperUI)`
  width: 40px;
  height: 40px;
  right: 10px;
  top: 10px;

  @media screen and (min-width: 1200px) {
    width: 70px;
    height: 70px;
    left: initial;
    right: 20px;
    top: 20px;
  }
`;

export const DiscountPercent = styled(DiscountPercentUI)`
  font-size: 13px;

  @media screen and (min-width: 1200px) {
    font-size: 19px;
  }
`;

export const DiscountText = styled(DiscountTextUI)`
  font-size: 7px;

  @media screen and (min-width: 1200px) {
    font-size: 12px;
  }
`;
