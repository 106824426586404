import React from 'react';
import { useSelector } from 'react-redux';

import { productsSelector } from 'common/selectors';

import { Links } from '../styled';
import LinkWithIcon from '../LinkWithIcon';

import renderCartAmount from './renderCartAmount';

const LinksRight = (props) => {
  const { isVitismuseumOpen, isSearchOpen, isCartOpen, onChangePopup } = props;
  const products = useSelector(productsSelector);

  return (
    <Links right>
      <LinkWithIcon
        to="/tickets"
        iconName="Tickets"
        iconWidth="23px"
        isActive={isVitismuseumOpen}
        iconMargin="0 7px 0 0"
        name="Посещение музея"
        marginRight={54}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('visitmuseum');
        }}
      />

      <LinkWithIcon
        to="/search"
        marginRight={45}
        iconName="Search"
        iconWidth="21px"
        isActive={isSearchOpen}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('search');
        }}
      />

      <LinkWithIcon
        to="/cart"
        iconName="Cart"
        iconWidth="24px"
        isActive={isCartOpen}
        isExtraVisible={products.length > 0}
        onClick={(e) => {
          e.preventDefault();
          onChangePopup('cart');
        }}
      >
        {renderCartAmount(products)}
      </LinkWithIcon>
    </Links>
  );
};

export default LinksRight;
