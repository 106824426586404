import React from 'react';
import styled from 'styled-components/macro';
import { Link as RLink, withRouter } from 'react-router-dom';

import { parseRelativePathSlug } from '../../Utils';

import HorizontalScroll from '../HorizontalScroll';

import books from './subParentPhotos/books.jpg';
import reproductions from './subParentPhotos/reproductions.jpg';
import home from './subParentPhotos/home.jpg';
import clothes from './subParentPhotos/clothes.jpg';
import jewerly from './subParentPhotos/jewerly.jpg';
import stationery from './subParentPhotos/stationery.jpg';
import kids from './subParentPhotos/kids.jpg';
import sculpture from './subParentPhotos/sculpture.jpg';
import collections from './subParentPhotos/collections.jpg';
import graphics from './subParentPhotos/graphics.jpg';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  border-top: 1px solid #ededed;

  @media screen and (min-width: 1200px) {
    margin: 0;
    border-top: none;
  }
`;

const Photo = styled.div`
  width: 102px;
  height: 102px;
  background-color: #f5f5f5;
  border-radius: 20px;
  background-image: ${(props) => (props.src ? `url(${props.src})` : 'none')};
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: auto 100%;
  margin: 0 auto;
  margin-bottom: 9px;
  transition: all 0.18s;

  @media screen and (min-width: 1200px) {
    width: 160px;
    height: 160px;
    margin-bottom: 15px;
  }
`;

const Name = styled.span`
  font-size: 10px;
  font-weight: 500;
  display: block;
  text-align: center;
  max-width: 102px;
  transition: all 0.18s;
  position: relative;
  z-index: 1;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    width: calc(100% + 23px);
    height: 100%;
    height: calc(100% + 9px);
    transform: translate(-50%, -50%) scale(0.3);
    border-radius: 10px;
    background-color: #e71d85;
    z-index: -1;
    opacity: 0;
    transition: all 0.18s;
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    max-width: 180px;

    &:after {
      display: none;
    }
  }
`;

const Category = styled(RLink)`
  text-decoration: none;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  transition: all 0.18s;

  &:first-child {
    /* padding-left: 40px; */
    padding-left: 0;
  }

  &:last-child {
    /* padding-right: 40px; */
    padding-right: 0;
  }

  ${Name} {
    color: ${(props) => (props.isActive ? '#ffffff' : '#000000')};

    &:after {
      opacity: ${(props) => (props.isActive ? 1 : 0)};
      transform: translate(-50%, -50%)
        scale(${(props) => (props.isActive ? 1 : 0.3)});
    }
  }

  &:hover {
    ${Photo} {
      opacity: ${(props) => (props.isActive ? '1' : '0.7')};
    }
  }

  @media screen and (min-width: 1200px) {
    padding: 20px;
    padding-top: 30px;
    padding-bottom: 0;

    ${Name} {
      color: ${(props) => (props.isActive ? '#e71d85' : '#000000')};
    }
  }
`;

class SubCategories extends React.Component {
  componentDidMount() {}

  getMainCategoryPhoto = (categoryID) => {
    const parentCategories = [
      {
        // books
        id: 59,
        photo: books,
      },
      {
        // reproductions
        id: 62,
        photo: reproductions,
      },
      {
        // home/decor
        id: 63,
        photo: home,
      },
      {
        // clothes and accessories
        id: 67,
        photo: clothes,
      },
      {
        // jewerly
        id: 60,
        photo: jewerly,
      },
      {
        // stationery
        id: 66,
        photo: stationery,
      },
      {
        // kids
        id: 65,
        photo: kids,
      },
      {
        // sculpture
        id: 265,
        photo: sculpture,
      },
      {
        // collections
        id: 300,
        photo: collections,
      },
      {
        // graphics
        id: 154,
        photo: graphics,
      },
    ];

    const categoryFinded = parentCategories.find(
      (category) => category.id === parseInt(categoryID, 10),
    );

    if (categoryFinded) {
      return categoryFinded.photo;
    }

    return null;
  };

  render() {
    const {
      currentSubcategory,
      history,
      parentCategorySlug,
      parentCategory,
    } = this.props;

    return (
      <Wrapper ref={(el) => (this.categoriesWrapper = el)}>
        <HorizontalScroll onChangeData={parentCategorySlug}>
          {this.props.subCategories.length > 0 && (
            <Category
              key="detailMenuSub_all"
              onClick={(e) => {
                if (window.ga) {
                  window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'DetailMenu: Sub Categories',
                    eventAction: 'click',
                    eventLabel: `ВСЕ | Parent: ${parentCategorySlug}`,
                  });
                }

                if (currentSubcategory !== 0) {
                  e.preventDefault();
                  history.push(`/category/${parentCategorySlug}`);
                }
              }}
              isActive={currentSubcategory === 0}
            >
              <Photo src={this.getMainCategoryPhoto(parentCategory)} />

              <Name>Все</Name>
            </Category>
          )}
          {this.props.subCategories.map((category) => (
            <Category
              to={`/category/${parseRelativePathSlug(category.slug)}`}
              href={`/category/${parseRelativePathSlug(category.slug)}`}
              key={`detailMenuSub_${category.id}`}
              isActive={currentSubcategory === parseInt(category.id, 10)}
              draggable={false}
              onClick={() => {
                if (window.ga) {
                  window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'DetailMenu: Sub Categories',
                    eventAction: 'click',
                    eventLabel: `${category.name} | ${parseRelativePathSlug(
                      category.slug,
                    )}`,
                  });
                }
              }}
            >
              <Photo src={category.photo} />

              <Name>{category.name}</Name>
            </Category>
          ))}
        </HorizontalScroll>
      </Wrapper>
    );
  }
}

export default withRouter(SubCategories);
