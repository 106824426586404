import React from 'react';
import styled, { css } from 'styled-components/macro';

import LinksLeft from './LinksLeft';
import LinksRight from './LinksRight';
import LinksMobile from './LinksMobile';
import Logo from './Logo';
import './hamburger.css';

const Background = styled.div`
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => (props.isOpenPopup ? 'rgba(250, 250, 250, 0)' : 'rgba(250, 250, 250, 1)')};
  transition: all 0.18s ease;

  ${props => props.openedPopup === 'search'
    && css`
      background-color: ${props.isOpenPopup
    ? 'rgba(250, 250, 250, 1)'
    : 'rgba(250, 250, 250, 1)'};
    `}

  @media screen and (min-width: 1200px) {
    background-color: ${props => (props.isOpenPopup ? 'rgba(255, 255, 255, 1)' : 'rgba(250, 250, 250, 1)')};
  }

  /* &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 87vw;
    background-color: rgba(255, 255, 255, 1);
    transform: ${props => (props.isOpenPopup ? 'translate(0, 0)' : 'translate(-100%, 0%)')};
    transition: all 0.25s ease;
  } */
`;

const Wrapper = styled.header`
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
  transform: translate(0, ${({ currentShift }) => `${currentShift.y}px`});
  width: 100%;
  height: 50px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  transition: all 0.25s ease;
  border-bottom: none;
  box-sizing: border-box;

  @media screen and (min-width: 1200px) {
    transition: all 0.35s ease;
    padding: 0 40px;
    height: 70px;
    border-bottom: ${props => (props.isOpenPopup
    ? '1px solid rgba(243, 243, 243, 1)'
    : '1px solid rgba(243, 243, 243, 0)')};

    ${Background} {
      ${props => props.pathScheme === '/product/:id'
        && css`
          background-color: ${props.isOpenPopup
    ? 'rgba(255, 255, 255, 1)'
    : 'transparent'};
        `}

      ${props => props.pathScheme === '/'
        && css`
          background-color: ${props.isOpenPopup
    ? 'rgba(255, 255, 255, 1)'
    : 'rgba(250, 250, 250, 1)'};
        `};
    }
  }
`;

const MobileOpen = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 33.33333%;
  transition: all 0.18s;
  visibility: ${props => (props.isPopupOpen && !props.isActive ? 'hidden' : 'visible')};
  opacity: ${props => (props.isPopupOpen && !props.isActive ? 0 : 1)};

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

class HeaderInner extends React.Component {
  innerDOM = null;

  logoDOM = null;

  innerBackgroundDOM = null;

  componentDidMount() {
    this.setHeaderRefs();
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.isPopupOpen !== this.props.isPopupOpen
      || this.props.openedPopup !== nextProps.openedPopup
      || nextProps.pathScheme !== this.props.pathScheme
    ) {
      return true;
    }

    return false;
  }

  setHeaderRefs = () => {
    if (
      this.innerDOM !== null
      && this.logoDOM !== null
      && this.innerBackgroundDOM !== null
    ) {
      this.props.setHeaderRefs(
        this.innerDOM,
        this.logoDOM,
        this.innerBackgroundDOM,
      );
    }
  };

  setLogoDOM = (logoDOM) => {
    this.logoDOM = logoDOM;
    this.setHeaderRefs();
  };

  render() {
    const {
      isPopupOpen,
      currentShift,
      onChangePopup,
      popups: {
        menu, personal, visitmuseum, cart, search, mobile,
      },
    } = this.props;

    return (
      <Wrapper
        id="header"
        isOpenPopup={isPopupOpen}
        ref={(e) => {
          this.innerDOM = e;
        }}
        currentShift={currentShift}
        pathScheme={this.props.pathScheme}
      >
        <Background
          id="header_background"
          ref={(e) => {
            this.innerBackgroundDOM = e;
          }}
          isOpenPopup={isPopupOpen}
          openedPopup={this.props.openedPopup}
        />

        <LinksLeft
          isMenuOpen={menu.isOpen}
          isPersonalOpen={personal.isOpen}
          onChangePopup={onChangePopup}
          isPopupOpen={isPopupOpen}
        />

        <MobileOpen
          onClick={() => onChangePopup('mobile')}
          isPopupOpen={isPopupOpen}
          isActive={mobile.isOpen}
        >
          <div
            className={`hamburger hamburger--minus ${
              mobile.isOpen ? 'is-active' : ''
            }`}
          >
            <div className="hamburger-box">
              <div className="hamburger-inner" />
            </div>
          </div>
        </MobileOpen>

        <Logo onRender={el => this.setLogoDOM(el)} />

        <LinksMobile
          isCartOpen={cart.isOpen}
          isSearchOpen={search.isOpen}
          isPopupOpen={isPopupOpen}
          onChangePopup={onChangePopup}
        />

        <LinksRight
          isCartOpen={cart.isOpen}
          isVitismuseumOpen={visitmuseum.isOpen}
          isSearchOpen={search.isOpen}
          onChangePopup={onChangePopup}
        />
      </Wrapper>
    );
  }
}

export default HeaderInner;
