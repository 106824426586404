function instockValue(value) {
  let instock = false;

  if (parseInt(value.instockquantity, 10) > 0) {
    instock = true;
  }

  return instock;
}

function instockCard(card) {
  let { instock } = card;
  let instockOptions = false;

  if (card.options && card.options.length > 0) {
    card.options.map((option) => {
      const findedLinkedOptionValue = option.values.find(
        (value) => value.idproduct === card.id,
      );

      if (findedLinkedOptionValue) {
        instock = instockValue(findedLinkedOptionValue);

        return option;
      }

      option.values.map((value) => {
        if (!value.idproduct && instockValue(value)) {
          instockOptions = true;
        }

        return value;
      });
    });
  }

  return instock || instockOptions;
}

export default function validateCards(cards) {
  let invalidCards = 0;

  // console.log(cards, 'before validate');

  const filteredCards = cards.filter((card) => {
    // if (card.id === '5374') {
    //   console.log(card, 'card');
    // }

    if (
      (parseFloat(card.price) > 0 || parseFloat(card.startPrice) > 0) &&
      instockCard(card)
    ) {
      return true;
    }

    invalidCards += 1;

    return false;
  });

  const withoutDublicates = filteredCards.filter(
    (card, index, self) =>
      index ===
      self.findIndex((c) => parseInt(c.id, 10) === parseInt(card.id, 10)),
  );

  invalidCards += filteredCards.length - withoutDublicates.length;

  return {
    cards: withoutDublicates,
    invalidCards,
  };
}
