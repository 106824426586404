import orderBy from 'lodash/orderBy';
import parseRelativePathSlug from './parseRelativePathSlug';

export default function getMainCategories(categories) {
  if (categories.length > 0) {
    const mainCategories = orderBy(
      categories,
      category => parseInt(category.priority, 10),
      ['asc'],
    ).filter((category) => {
      if (
        parseInt(category.parentID, 10) === 0
        && !category.hidden
        && category.status
        && !!parseRelativePathSlug(category.slug)
      ) {
        return category;
      }

      return null;
    });

    return mainCategories;
  }

  return [];
}
