import React from 'react';
import styled from 'styled-components/macro';
import { Link as RLink } from 'react-router-dom';

import { Button } from '../../UI';

const Wrapper = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  display: block;
`;

const EmptyTitle = styled.h3`
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;

  @media screen and (min-width: 1200px) {
    font-size: 24px;
    margin-top: 40px;
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin-bottom: 40px;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  margin-top: 30px;
`;

const Circle = styled.div`
  width: 100px;
  height: 100px;
  background-color: #f3f3f3;
  border-radius: 100%;
  margin: 0 auto;
  margin-top: 40px;

  @media screen and (min-width: 1200px) {
    margin-top: 160px;
    width: 200px;
    height: 200px;
  }
`;

const Description = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 0;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    margin-top: 70px;
  }
`;

const ContactDescription = styled.p`
  color: #656565;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 0;
  margin-top: 15px;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    margin-top: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 0px 25px;
`;

const ButtonWrapperInner = styled.div`
  width: 100%;
`;

const Br = styled.br`
  display: ${props => (props.visibleMobile ? 'initial' : 'none')};

  @media screen and (min-width: 1200px) {
    display: initial;
  }
`;

const Link = styled(RLink)`
  color: #e71d85;
  text-decoration: none;
  transition: all 0.18s;

  &:hover {
    opacity: 0.6;
  }
`;

const FakeLink = styled.a`
  color: #e71d85;
  text-decoration: none;
  transition: all 0.18s;

  &:hover {
    opacity: 0.6;
  }
`;

const Phone = styled.a`
  text-decoration: none;
  color: inherit;
`;

export default class Empty extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Wrapper>
        <Content>
          <Circle />
          <EmptyTitle>В корзине всё ещё пусто</EmptyTitle>

          <Description>
            Возможно, вы не можете найти что-то по душе —
            {' '}
            <Br />
            посмотрите нашу категорию
            {' '}
            <Link to="/category/bestsellers" href="/category/bestsellers">
              Хиты продаж
            </Link>
          </Description>

          <Description>
            Если у вас есть какие-то вопросы или вы просто хотите
            {' '}
            <Br />
            поговорить —
            {' '}
            <FakeLink
              href="#openchat"
              onClick={(e) => {
                e.preventDefault();

                if (window.jivo_api) {
                  window.jivo_api.open();
                }
              }}
            >
              напишите нам в Чат
            </FakeLink>
          </Description>

          <ContactDescription>
            или позвоните по телефону
            <Br visibleMobile />
            <Phone href="tel:+78124297407">+7 (812) 429-74-07</Phone>
          </ContactDescription>

          <ButtonWrapper>
            <ButtonWrapperInner>
              <Button to="#" onClick={this.props.onClose}>
                Продолжить покупки
              </Button>
            </ButtonWrapperInner>
          </ButtonWrapper>
        </Content>
      </Wrapper>
    );
  }
}
