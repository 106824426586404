import React from 'react';
import 'normalize.css';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { Helmet } from 'react-helmet';

import Route from './Layout';
import { LoadingRoute } from './UI';
import store from './store';

// Pages
const loadDelay = 300;

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "Home" */ './Home'),
  modules: ['Home'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Products = Loadable({
  loader: () => import(/* webpackChunkName: "Products" */ './Products'),
  modules: ['Products'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const ProductPage = Loadable({
  loader: () => import(/* webpackChunkName: "ProductPage" */ './ProductPage'),
  modules: ['ProductPage'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Vouchers = Loadable({
  loader: () => import(/* webpackChunkName: "Vouchers" */ './Vouchers'),
  modules: ['Vouchers'],
  loading: LoadingRoute,
  delay: loadDelay,
});

// const VR = Loadable({
//   loader: () => import('./VR'),
//   loading: LoadingRoute,
//   delay: loadDelay,
// });

const About = Loadable({
  loader: () => import(/* webpackChunkName: "About" */ './About'),
  modules: ['About'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const FAQ = Loadable({
  loader: () => import(/* webpackChunkName: "FAQ" */ './FAQ'),
  modules: ['FAQ'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Shipping = Loadable({
  loader: () => import(/* webpackChunkName: "Shipping" */ './Shipping'),
  modules: ['Shipping'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Exchange = Loadable({
  loader: () => import(/* webpackChunkName: "Exchange" */ './Exchange'),
  modules: ['Exchange'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Warranty = Loadable({
  loader: () => import(/* webpackChunkName: "Warranty" */ './Warranty'),
  modules: ['Warranty'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const NotFound = Loadable({
  loader: () => import(/* webpackChunkName: "NotFound" */ './NotFound'),
  modules: ['NotFound'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Checkout = Loadable({
  loader: () => import(/* webpackChunkName: "Checkout" */ './Checkout'),
  modules: ['Checkout'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Search = Loadable({
  loader: () => import(/* webpackChunkName: "Search" */ './Search'),
  modules: ['Search'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const SubscribeConfirm = Loadable({
  loader: () =>
    import(/* webpackChunkName: "SubscribeConfirm" */ './SubscribeConfirm'),
  modules: ['SubscribeConfirm'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const CheckoutSuccess = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Checkout/Success" */ './Checkout/Success'),
  modules: ['Checkout/Success'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const RegisterConfirm = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RegisterConfirm" */ './RegisterConfirm'),
  modules: ['RegisterConfirm'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const Organization = Loadable({
  loader: () => import(/* webpackChunkName: "Organization" */ './Organization'),
  modules: ['Organization'],
  loading: LoadingRoute,
  delay: loadDelay,
});

const OrderChange = Loadable({
  loader: () => import(/* webpackChunkName: "OrderChange" */ './OrderChange'),
  modules: ['OrderChange'],
  loading: LoadingRoute,
  delay: loadDelay,
});

// let history = [];

// if (typeof window !== 'undefined') {
//   history = createMemoryHistory();
// } else {
//   history = createBrowserHistory();
// }

// export default () => (
//   <Provider store={store}>
//     <Helmet
//       titleTemplate="%s ● Эрмитажный магазин"
//       defaultTitle="Эрмитажный магазин"
//     />

//     <Route path="/" exact component={Home} />
//   </Provider>
// );

export default class Routes extends React.Component {
  componentDidMount() {}

  renderRoutes = () => (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/category/:category" exact component={Products} />
      <Route
        path="/category/:category/:subcategory"
        exact
        component={Products}
      />
      <Route path="/product/:id" exact component={ProductPage} />
      <Route path="/product/code/:model" exact component={ProductPage} />
      <Route path="/tickets" exact component={Vouchers} />
      <Route path="/vouchers/success" component={Vouchers} />
      <Route
        path="/subscribe/confirm/:hash"
        exact
        component={SubscribeConfirm}
      />
      {/* <Route path="/vr" exact component={VR} /> */}
      <Route path="/about" exact component={About} />
      <Route path="/orderchange" exact component={OrderChange} />
      <Route path="/faq" exact component={FAQ} />
      <Route path="/shipping" exact component={Shipping} />
      <Route path="/exchange" exact component={Exchange} />
      <Route path="/warranty" exact component={Warranty} />
      <Route path="/checkout" exact component={Checkout} />
      <Route path="/organization" exact component={Organization} />
      <Route path="/checkout/success" exact component={CheckoutSuccess} />
      <Route path="/register/confirm/:hash" exact component={RegisterConfirm} />
      <Route path="/search/:search" exact component={Search} />
      <Route component={NotFound} />
    </Switch>
  );

  startHelmet = () => (
    <Helmet
      titleTemplate="%s • Эрмитажный магазин"
      defaultTitle="Эрмитажный магазин"
    >
      <meta property="og:url" content="https://www.hermitageshop.ru" />

      <meta
        property="og:image"
        content="https://www.hermitageshop.ru/favicons/favicon_192.png"
      />
    </Helmet>
  );

  render() {
    if (typeof window !== 'undefined') {
      return (
        <Provider store={store}>
          {this.startHelmet()}

          <BrowserRouter>{this.renderRoutes()}</BrowserRouter>
        </Provider>
      );
    }

    return <Provider store={store}>{this.renderRoutes()}</Provider>;
  }
}
