// only mobile component

import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Link as RLink } from 'react-router-dom';

import { parseRelativePathSlug, getMainCategories } from '../Utils';

import testPhoto from '../Home/mobilePhotos/banner1.png';

const Wrapper = styled.div`
  display: block;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const List = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding-top: 5px;
  margin-left: -5px;
  padding-left: 5px;

  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Circle = styled.div`
  position: relative;
  background-color: #656565;
  border-radius: 100%;
  height: 60px;
  width: 60px;
  background-image: url(${props => encodeURI(props.photo)});
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 112%;
    width: calc(100% + 7px);
    height: 112%;
    height: calc(100% + 7px);
    border-radius: 100%;
    border: 2px solid #e71d85;
    box-sizing: border-box;
    transform: translate(-50%, -50%) scale(0.8);
    transition: all 0.18s;
    opacity: 0;
  }
`;

const Name = styled.span`
  font-size: 11px;
  font-weight: 600;
  margin-top: 10px;
  display: block;
`;

const Category = styled(RLink)`
  flex: 0 0 60px;
  margin-right: 15px;
  text-align: center;
  text-decoration: none;
  color: inherit;

  ${props => props.pathname === '/'
    && css`
      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
      }
    `};

  ${props => props.isActive
    && css`
      ${Circle} {
        &:after {
          transform: translate(-50%, -50%) scale(1);
          opacity: 1;
        }
      }

      ${Name} {
        color: #e71d85;
      }
    `};
`;

export default ({
  categories,
  pathname,
  subCategories,
  parentCategory,
  currentSubcategory,
  parentCategorySlug,
}) => (
  <Wrapper>
    <List>
      {getMainCategories(categories).map(category => (
        <Category
          to={`/category/${parseRelativePathSlug(category.slug)}`}
          href={`/category/${parseRelativePathSlug(category.slug)}`}
          key={`storiesPoint_${category.id}`}
          onClick={() => {
            if (window.ga) {
              window.ga('send', {
                hitType: 'event',
                eventCategory: 'Stories',
                eventAction: 'click',
                eventLabel: `${category.name} | ${parseRelativePathSlug(
                  category.slug,
                )}`,
              });
            }
          }}
          pathname={pathname}
          isActive={
            pathname
              ? pathname === `/category/${parseRelativePathSlug(category.slug)}`
              : false
          }
        >
          <Circle photo={category.photo ? category.photo : testPhoto} />
          <Name>{category.name}</Name>
        </Category>
      ))}
    </List>
  </Wrapper>
);
