import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components/macro';
import { connect } from 'react-redux';

import { modalsTypes } from '../../Modals/propTypes';

import { addEventWheel } from '../../Utils';

import { cross } from '../icons';

import Content from './Content';

const ModalBackground = styled.div`
  position: fixed;
  z-index: ${props => props.zIndex};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(101, 101, 101, 0.8);
  opacity: ${props => (props.isOpen ? 1 : 0)};
  cursor: pointer;
  transition: opacity 0.18s ease;
`;

const changePosition = openingPoint => keyframes`
  0% {
    left: ${openingPoint.x}px;
    top: ${openingPoint.y}px;
  }

  100% {
    left: 50%;
    top: 50%;
  }
`;

const PreWrapper = styled.div`
  position: fixed;
  /* left: ${props => props.openingPoint.x}px;
  top: ${props => props.openingPoint.y}px; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-flex;
  max-width: 90%;
  max-height: 90%;
  z-index: ${props => props.zIndex};
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  /* border-radius: 50%; */
  /* transition: transform 1s ease, border-radius 0.7s ease 0.45s; */
  transition: opacity 0.3s ease;

  /* &:after {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    visibility: visible;
    background-color: rgba(255, 255, 255, 1);
    transition: background-color 0.7s ease 0.45s, visibility 0.01s linear 0.95s;
  }

  &:before {
    content: '';
    padding-top: 100%;
    display: table;
    visibility: visible;
    transition: padding 0.5s linear 0.45s;
  } */

  ${props => props.isOpen
    && css`
      visibility: visible;
      opacity: 1;
      /* border-radius: 0; */
      /* transform: translate(-50%, -50%) scale(1); */
      /* animation: ${changePosition(props.openingPoint)} 0.7s
        cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards; */
      /* animation: ${changePosition(props.openingPoint)} 0.6s */
        /* linear forwards; */
      /* animation-delay: 0.1s; */

      /* &:after {
        background-color: rgba(255, 255, 255, 0);
        visibility: hidden;
      }

      &:before {
        padding-top: 0;
      } */
    `};
`;

const Wrapper = styled.div`
  width: 770px;
  height: auto;
  overflow: auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
`;

const Cross = styled.button`
  outline: none;
  border: none;
  background: none;
  padding: 0;
  background-image: url(${cross});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 15px;
  height: 15px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

class Modal extends React.Component {
  zIndexBackground = 100;

  step = 10;

  zIndexModal = 110; // 100 + step

  constructor(props) {
    super(props);

    if (!this.canUseDOM()) {
      return null;
    }

    this.modal = document.createElement('div');
  }

  componentDidMount() {
    const modalRoot = document.getElementById('modal-root');
    modalRoot.appendChild(this.modal);
    addEventWheel(true, this.modalBackground, this.onWheel);
  }

  shouldComponentUpdate(nextProps) {
    const { isCustomUpdateComponent, isOpen } = this.props;

    const { modals } = nextProps;

    if (!isCustomUpdateComponent) {
      if (nextProps.isOpen === isOpen) {
        return false;
      }
    }

    if (nextProps.isOpen && !isOpen) {
      const numModals = this.getNumOpenedModals(modals);

      this.zIndexBackground = this.zIndexBackground
        + (this.zIndexModal - this.zIndexBackground)
        + this.step * numModals;
      this.zIndexModal = this.zIndexModal + this.step * numModals + 5;
    }

    if (!nextProps.isOpen) {
      this.zIndexBackground = 100;
      this.zIndexModal = 110;
    }

    return true;
  }

  componentWillUnmount() {
    addEventWheel(false, this.modalBackground, this.onWheel);
  }

  canUseDOM = () => !!(
    typeof window !== 'undefined'
      && window.document
      && window.document.createElement
  );

  onWheel = (e) => {
    e.preventDefault();
  };

  getNumOpenedModals = (modals) => {
    const nameModals = Object.keys(modals);

    const openedModals = nameModals.filter(name => modals[name].isOpen).length;

    return openedModals;
  };

  render() {
    if (!this.canUseDOM()) {
      return null;
    }

    const {
      isOpen,
      onClose,
      openingPoint,
      children,
    } = this.props;

    const html = (
      <>
        <ModalBackground
          onClick={onClose}
          isOpen={isOpen}
          zIndex={this.zIndexBackground}
          ref={e => (this.modalBackground = e)}
        />

        <PreWrapper
          isOpen={isOpen}
          zIndex={this.zIndexModal}
          openingPoint={openingPoint}
        >
          <Wrapper isOpen={isOpen}>
            <Cross onClick={onClose} />
            <Content isOpen={isOpen}>{children}</Content>
          </Wrapper>
        </PreWrapper>
      </>
    );

    return ReactDOM.createPortal(html, this.modal);
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  openingPoint: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  children: PropTypes.element.isRequired,
  isCustomUpdateComponent: PropTypes.bool,
  modals: modalsTypes.isRequired,
};

Modal.defaultProps = {
  openingPoint: {
    x: 0,
    y: 0,
  },
  isCustomUpdateComponent: false,
};

export default connect(store => ({ modals: store.modals }))(Modal);
