import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/browser';

import Header from '../Header';
import Footer from '../Footer';
import Popups from '../Popups';

import { addEventWheel, isPopupOpen } from '../Utils';

// import Notifications from '../Notifications';
import Modals from '../Modals';

import Wrapper from './Wrapper';
import SubLayout from './SubLayout';
import oldRoutes from './oldRoutes';

const Fade = styled.div`
  position: fixed;
  z-index: 25;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  background-color: rgba(221, 221, 221, 0.8);
  opacity: ${(props) => (props.isOpenPopup ? '1' : '0')};
  transition: all 0.3s;
  visibility: ${(props) => (props.isOpenPopup ? 'visible' : 'hidden')};
`;

class Layout extends React.Component {
  currentScroll = 0;

  headerDOM = null;

  footerDOM = null;

  wrapperDOM = null;

  waitClosingPopup = false;

  prevPath = null;

  static defaultProps = { component: React.createElement('div') };

  shouldComponentUpdate(nextProps) {
    const {
      location: { pathname: nextPath },
      onChangePopup,
    } = nextProps;

    const {
      location: { pathname: currentPath },
    } = this.props;

    if (nextPath !== currentPath) {
      if (isPopupOpen(nextProps.popups)) {
        this.waitClosingPopup = true;
        this.prevPath = currentPath;
        onChangePopup(false);
        return false;
      }
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname: currentPath },
      popups,
    } = this.props;

    const {
      location: { pathname: prevPath },
    } = prevProps;

    if (this.waitClosingPopup) {
      this.waitClosingPopup = false;
      setTimeout(() => {
        window.scrollTo(0, 0);

        if (currentPath !== this.prevPath && window.ga) {
          window.ga('set', 'page', window.location.pathname);
          window.ga('send', 'pageview');
          this.prevPath = null;
        }

        this.forceUpdate();
      }, 300);
    } else if (currentPath !== prevPath) {
      if (window.ga) {
        window.ga('set', 'page', window.location.pathname);
        window.ga('send', 'pageview');
      }
      window.scrollTo(0, 0);
    }

    if (isPopupOpen(popups)) {
      // if (currentPath === '/' && window.innerWidth >= 1200) {
      //   window.scrollTo(0, 0); // temporary
      // }
      if (this.wrapperDOM) {
        this.wrapperDOM.addEventListener('click', this.onClickClosePopup);
        addEventWheel(true, this.wrapperDOM, this.onWheelWrapper);
      }

      if (this.footerDOM) {
        this.footerDOM.addEventListener('click', this.onClickClosePopup);
      }
    } else {
      if (this.wrapperDOM) {
        addEventWheel(false, this.wrapperDOM, this.onWheelWrapper);
        this.wrapperDOM.removeEventListener('click', this.onClickClosePopup);
      }

      if (this.footerDOM) {
        this.footerDOM.removeEventListener('click', this.onClickClosePopup);
      }
    }
  }

  onWheelWrapper = (e) => {
    e.preventDefault();
  };

  onClickClosePopup = () => {
    // alert('hey');
    this.props.onChangePopup(false);
  };

  getToken = () => (window ? window.localStorage.getItem('auth_token') : false);

  getApiInstance = () => {
    const apiInstance = axios.create({
      // baseURL: 'https://dev.hermitageshop.ru/api/v2',
      baseURL:
        process.env.NODE_ENV === 'development'
          ? 'https://dev.hermitageshop.ru/api/v2'
          : '/api/v2',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic dGVzdDp0ZXN0MTIzNDU2',
      },
    });

    return apiInstance;
  };

  checkOldRoute = () => {
    const currentPathName = this.props.location.pathname;

    const oldProductRoute = currentPathName.match(
      /[^\\/(?!index)]*(?=\.html.*$)/,
    );

    if (oldProductRoute) {
      if (currentPathName === '/buytickets.html') {
        this.props.history.replace('/tickets');
      } else if (currentPathName === '/warranty.html') {
        this.props.history.replace('/warranty');
      } else if (currentPathName === '/about.html') {
        this.props.history.replace('/about');
      } else if (currentPathName === '/delivery-and-payment.html') {
        this.props.history.replace('/shipping');
      } else {
        this.props.history.replace(`/product/code/${oldProductRoute[0]}`);
      }
    }

    const oldCategoriesSlug = oldRoutes;

    Object.keys(oldCategoriesSlug).map((originalURI) => {
      if (
        `/${originalURI}` === this.props.location.pathname ||
        `/${originalURI}/` === this.props.location.pathname
      ) {
        this.props.history.replace(
          `/category/${oldCategoriesSlug[originalURI]}`,
        );
      }

      return true;
    });
  };

  setHeaderRef = (headerDOM) => {
    this.headerDOM = headerDOM;
  };

  setFooterRef = (footerDOM) => {
    this.footerDOM = footerDOM;
  };

  getHeaderHeight = () => {
    if (this.headerDOM) {
      return this.headerDOM.offsetHeight;
    }

    return 70; // standart full height
  };

  getFooterHeight = () => {
    if (this.footerDOM) {
      return this.footerDOM.offsetHeight;
    }

    return 336; // standart full height
  };

  sendError = (extra, title, pageName) => {
    if (!extra || !title || !pageName) {
      return false;
    }

    let eventID = false;

    Sentry.withScope((scope) => {
      scope.setExtras(extra);
      scope.setTag('page', pageName);
      scope.setTag('individual_errors', 'true');

      eventID = Sentry.captureEvent({
        message: title,
      });
    });

    return eventID;
  };

  render() {
    const {
      location: { pathname },
      popups,
      // popups: { currentShift },
      component: Component,
      onChangePopup,
      ...rest
    } = this.props;

    const isPopupOpened = isPopupOpen(popups);

    let isDisableScrollHeader = false;

    if (pathname === '/') {
      isDisableScrollHeader = true;
    }

    const apiInstance = this.getApiInstance();

    this.checkOldRoute();

    return (
      <>
        <Header
          scrollingDisabled={isDisableScrollHeader}
          currentPath={pathname}
          isPopupOpened={isPopupOpened}
          apiInstance={apiInstance}
          pathScheme={this.props.path}
          setHeaderRef={this.setHeaderRef}
          onChangePopup={this.props.onChangePopup}
          popups={popups}
        />

        <Popups
          isPopupOpened={isPopupOpened}
          apiInstance={apiInstance}
          currentPath={pathname}
          pathScheme={this.props.path}
          getHeaderHeight={this.getHeaderHeight}
        />

        <Fade
          isOpenPopup={isPopupOpened}
          onClick={() => this.props.onChangePopup(false)}
        />

        <Wrapper
          getFooterHeight={this.getFooterHeight}
          currentPath={pathname}
          setRef={(el) => {
            this.wrapperDOM = el;
          }}
        >
          <SubLayout
            isOpenPopup={isPopupOpened}
            // currentShift={currentShift}
            pathname={this.props.location.pathname}
          >
            <Route
              {...rest}
              render={(props) => (
                <>
                  {Component && (
                    <Component
                      {...props}
                      getHeaderHeight={this.getHeaderHeight}
                      getFooterHeight={this.getFooterHeight}
                      apiInstance={apiInstance}
                      onChangePopup={this.props.onChangePopup}
                      onChangeModal={this.props.onChangeModal}
                      sendError={this.sendError}
                    />
                  )}
                </>
              )}
            />
          </SubLayout>
        </Wrapper>

        {/* <Notifications
          getFooterHeight={this.getFooterHeight}
          isOpenPopup={isPopupOpened}
          pathScheme={this.props.path}
        /> */}

        <Modals apiInstance={apiInstance} />

        <Footer
          isOpenPopup={isPopupOpened}
          setRefs={this.setFooterRef}
          currentPath={pathname}
          // currentShift={currentShift}
          apiInstance={apiInstance}
          onChangePopup={this.props.onChangePopup}
          onChangeModal={this.props.onChangeModal}
        />
      </>
    );
  }
}

export default withRouter(
  connect(
    (store) => ({ popups: store.popups, modals: store.modals }),
    (dispatch) => ({
      onChangePopup: (popup) => {
        dispatch({ type: 'CHANGE_POPUP', payload: popup });
      },
      onChangeModal: (modal) => {
        dispatch({ type: 'CHANGE_MODAL', payload: modal });
      },
    }),
  )(Layout),
);
