import React from 'react';
import styled from 'styled-components/macro';
import { Grid, Row, Col } from 'react-flexbox-grid';
import isEqual from 'lodash/isEqual';

import Hermitage from './Hermitage';

import {
  TopWrapper as Wrapper,
  SupportTitle,
  Point,
  SupportPoint,
  Phone,
  Email,
  Title,
  LinkTop as Link,
  Tab,
  TabContent,
  FakeLink,
} from './styled';

const HermitageDesktop = styled.div`
  padding-right: 33px;
  padding-top: 15px;
`;

export default class Top extends React.Component {
  state = {
    lk: false,
    museum: false,
    offer: false,
    information: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !isEqual(nextState, this.state) ||
      this.props.homePage !== nextProps.homePage
    ) {
      return true;
    }

    return false;
  }

  onHandleLk = () => this.setState({ lk: !this.state.lk });

  onHandleMuseum = () => this.setState({ museum: !this.state.museum });

  onHandleOffer = () => this.setState({ offer: !this.state.offer });

  onHandleInformation = () =>
    this.setState({ information: !this.state.information });

  render() {
    return (
      <Wrapper>
        <Grid fluid={!!this.props.homePage}>
          <Row around="xl">
            <Col xs={12} xl={2}>
              <Tab alwaysOpen>
                <SupportTitle>Служба поддержки</SupportTitle>

                <Point>
                  <Phone
                    href="tel:+78124297407"
                    onClick={() => {
                      if (window.ga) {
                        window.ga('send', {
                          hitType: 'event',
                          eventCategory: 'Footer Phone Number',
                          eventAction: 'click',
                        });
                      }
                    }}
                  >
                    +7 (812) 429-74-07
                  </Phone>
                </Point>

                <Point>
                  <Email
                    href="mailto:info@hermitageshop.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      if (window.ga) {
                        window.ga('send', {
                          hitType: 'event',
                          eventCategory: 'Footer Email',
                          eventAction: 'click',
                        });
                      }
                    }}
                  >
                    info@hermitageshop.com
                  </Email>
                </Point>

                <Point>
                  <SupportPoint
                    accent
                    onClick={() => {
                      if (window.jivo_api) {
                        window.jivo_api.open();
                      }

                      if (window.ga) {
                        window.ga('send', {
                          hitType: 'event',
                          eventCategory: 'Footer Chat',
                          eventAction: 'open',
                          eventLabel: window.jivo_api
                            ? 'loaded widget'
                            : 'not loaded widget',
                        });
                      }
                    }}
                  >
                    Чат с поддержкой
                  </SupportPoint>
                  {/* <SupportPoint>Помощь при покупке</SupportPoint> */}
                </Point>
              </Tab>
            </Col>

            <Col xs={12} xl={2}>
              <Tab>
                <Title isOpen={this.state.lk} onClick={this.onHandleLk}>
                  Личный кабинет
                </Title>

                <TabContent isOpen={this.state.lk}>
                  <Point>
                    <FakeLink
                      onClick={() =>
                        this.props.onChangePopup({ personal: { isOpen: true } })
                      }
                    >
                      Войти | Регистрация
                    </FakeLink>
                  </Point>

                  {/* <Point>
                    <FakeLink
                      onClick={() => this.props.onChangePopup({ personal: { isOpen: true } })
                      }
                    >
                      История заказов
                    </FakeLink>
                  </Point> */}

                  <Point>
                    <Link to="/orderchange" href="/orderchange">
                      Изменение заказа
                    </Link>
                  </Point>

                  <Point>
                    <FakeLink
                      onClick={(e) => {
                        if (window.ga) {
                          window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'Footer Subscribe Modal',
                            eventAction: 'open',
                          });
                        }

                        this.props.onChangeModal({
                          name: 'subscribe',
                          openingPoint: {
                            x: e.clientX,
                            y: e.clientY,
                          },
                        });
                      }}
                    >
                      Подписка
                    </FakeLink>
                  </Point>
                </TabContent>
              </Tab>
            </Col>

            <Col xs={12} xl={2}>
              <Tab>
                <Title isOpen={this.state.museum} onClick={this.onHandleMuseum}>
                  Посещение музея
                </Title>

                <TabContent isOpen={this.state.museum}>
                  <Point>
                    <Link to="/tickets" href="/tickets">
                      Ваучеры
                    </Link>
                  </Point>

                  <Point>
                    <FakeLink
                      onClick={(e) =>
                        this.props.onChangeModal({
                          name: 'excursionsInfo',
                          openingPoint: {
                            x: e.clientX,
                            y: e.clientY,
                          },
                        })
                      }
                    >
                      Экскурсии
                    </FakeLink>
                  </Point>

                  <Point>
                    <Link to="/organization" href="/organization">
                      Общие вопросы
                    </Link>
                  </Point>

                  {/* <Point>
                    <Link to="/tickets" href="/tickets">
                      Аудиогид
                    </Link>
                  </Point> */}
                </TabContent>
              </Tab>
            </Col>
            {this.props.homePage ? (
              ''
            ) : (
              <Col xs={12} xl={2}>
                <Tab>
                  <Title isOpen={this.state.offer} onClick={this.onHandleOffer}>
                    Предложения
                  </Title>

                  <TabContent isOpen={this.state.offer}>
                    <Point>
                      <Link
                        to="/category/bestsellers"
                        href="/category/bestsellers"
                      >
                        Хиты продаж
                      </Link>
                    </Point>

                    <Point>
                      <Link
                        to="/category/new-arrivals"
                        href="/category/new-arrivals"
                      >
                        Новинки
                      </Link>
                    </Point>

                    {/* <Point>
                    <Link to="/products" href="/products">
                      Скидки
                    </Link>
                  </Point> */}
                  </TabContent>
                </Tab>
              </Col>
            )}

            <Col xs={12} xl={2}>
              <Tab>
                <Title
                  isOpen={this.state.information}
                  onClick={this.onHandleInformation}
                >
                  Информация
                </Title>

                <TabContent isOpen={this.state.information}>
                  <Point>
                    <Link to="/about" href="/about">
                      О магазине
                    </Link>
                  </Point>

                  <Point>
                    <Link to="/shipping" href="/shipping">
                      Доставка и оплата
                    </Link>
                  </Point>

                  <Point>
                    <Link to="/exchange" href="/exchange">
                      Возврат и обмен
                    </Link>
                  </Point>

                  <Point>
                    <Link to="/warranty" href="/warranty">
                      Гарантия и качество
                    </Link>
                  </Point>
                </TabContent>
              </Tab>
            </Col>

            <Col xl={2} className="hidden-lg hidden-md hidden-sm hidden-xs">
              <HermitageDesktop>
                <Hermitage mobile={false} />
              </HermitageDesktop>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
    );
  }
}
