import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';

import * as Sentry from '@sentry/browser';

import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import App from './App';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';

Sentry.init({
  dsn: 'https://500ad91407ca4a42be4779511c39de53@sentry.io/1380237',
});

unregisterServiceWorker();

smoothscroll.polyfill();

const root = document.getElementById('root');

if (root !== null) {
  if (process.env.NODE_ENV === 'production') {
    // ReactDOM.hydrate(<App />, root);
    window.onload = () => {
      Loadable.preloadReady().then(() => {
        ReactDOM.hydrate(<App />, document.getElementById('root'));
      });
    };
  } else {
    ReactDOM.render(<App />, root);
  }
}
