import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  text-align: center;
`;

export default () => (
  <Wrapper>
    <div className="lds-ellipsis">
      <div />
      <div />
      <div />
      <div />
    </div>
  </Wrapper>
);
