import React from 'react';

import Ingerprint from './Ingerprint';
import Items from './Items';
import Tickets from './Tickets';
import Cart from './CartIcon';
import Search from './SearchIcon';

import { Link, LinkWrap } from './styled';

export default class LinkWithIcon extends React.Component {
  state = {
    isHover: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { nextIsActive } = nextProps;
    const { nextIsHover } = nextState;
    const { isActive } = this.props;
    const { isHover } = this.state;

    if (
      (nextProps !== undefined && nextIsActive !== isActive)
      || (nextIsHover !== undefined && nextIsHover !== isHover)
    ) {
      return true;
    }

    return false;
  }

  render() {
    const {
      to,
      isActive,
      isExtraVisible,
      onClick,
      marginRight,
      iconWidth,
      iconMargin,
      name,
      iconName,
      hideOnActiveMobile,
      isPopupOpen,
      children,
    } = this.props;

    const { isHover } = this.state;

    let Icon = <div />;

    switch (iconName) {
      case 'Items':
        Icon = Items;
        break;

      case 'Ingerprint':
        Icon = Ingerprint;
        break;

      case 'Tickets':
        Icon = Tickets;
        break;

      case 'Search':
        Icon = Search;
        break;

      case 'Cart':
        Icon = Cart;
        break;

      default:
        Icon = <div />;
        break;
    }

    return (
      <LinkWrap
        customMarginRight={marginRight}
        isActive={!!isActive}
        isPopupOpen={isPopupOpen}
        hideOnActiveMobile={!!hideOnActiveMobile}
      >
        <Link
          href={to}
          to={to}
          onClick={onClick}
          onMouseEnter={() => this.setState({ isHover: true })}
          onMouseLeave={() => this.setState({ isHover: false })}
        >
          <Icon
            width={iconWidth || ''}
            margin={iconMargin || ''}
            isHover={isActive || isHover}
            isExtraVisible={!!isExtraVisible}
          />
          {name || ''}
          {children}
        </Link>
      </LinkWrap>
    );
  }
}
