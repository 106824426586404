import React from 'react';

import { Popup } from '../../UI';

import Main from './Main';

import pushEventOpenGA from '../pushEventOpenGA';

class Visitmuseum extends React.Component {
  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    const newOpenState = nextProps.isOpen !== this.props.isOpen;
    if (newOpenState) {
      return true;
    }

    return false;
  }

  componentDidUpdate() {
    const { isOpen } = this.props;

    pushEventOpenGA(isOpen, 'Visitmuseum');
  }

  render() {
    const headerHeight = this.props.getHeaderHeight();

    return (
      <Popup
        isOpen={this.props.isOpen}
        headerHeight={headerHeight}
        width={this.props.width}
        location="right"
        onClose={this.props.onClose}
      >
        <Main />
      </Popup>
    );
  }
}

export default Visitmuseum;
