import styled from 'styled-components';

export const DiscountWrapper = styled.div`
  width: 60px;
  height: 60px;
  background-color: #e71d85;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;

  @media screen and (min-width: 1200px) {
    width: 100px;
    height: 100px;
    right: initial;
    left: 40px;
    top: 110px;
  }
`;

export const DiscountPercent = styled.span`
  font-size: 18px;
  font-weight: 300;

  @media screen and (min-width: 1200px) {
    font-size: 31px;
  }
`;

export const DiscountText = styled.span`
  font-size: 11px;
  font-weight: 500;

  @media screen and (min-width: 1200px) {
    font-size: 15px;
  }
`;
