import React from 'react';
import styled from 'styled-components/macro';

import { Link as RLink } from 'react-router-dom';

const Wrapper = styled.div`
  padding: 40px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Link = styled(RLink)`
  font-weight: 400;
  font-size: 15px;
  text-decoration: none;
  color: #000000;
  margin-bottom: 20px;
  transition: all 0.18s;

  &:hover {
    color: #e71d85;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    font-size: 19px;
  }
`;

const DisabledLink = styled.span`
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  color: #f0f0f0;
  margin-bottom: 20px;
  transition: all 0.18s;

  &:hover {
    color: #f0f0f0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media screen and (min-width: 1200px) {
    font-size: 19px;
  }
`;

export default class Visitmuseum extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <Wrapper
        ref={(el) => {
          this.props.setScrollableContainer(el);
        }}
      >
        <List>
          <Link to="/tickets" href="/tickets">
            Входные ваучеры и экскурсии
          </Link>

          <DisabledLink>Аудиогид</DisabledLink>

          <DisabledLink>VR - Experience</DisabledLink>
        </List>
      </Wrapper>
    );
  }
}
