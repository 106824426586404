export default function amountPrice(cart) {
  let fullPrice = 0;

  cart.map((product) => {
    if (product.discount) {
      fullPrice += product.discount * product.quantity;
    } else {
      fullPrice += product.price * product.quantity;
    }
    return product;
  });

  return parseFloat(fullPrice);
}
