import styled from 'styled-components/macro';

const Title4 = styled.h4`
  font-size: 19px;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;
`;

export default Title4;
