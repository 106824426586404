import React from 'react';

export default class WrapperLayout extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (
      (nextProps.isOpenPopup || this.props.isOpenPopup)
      // || nextProps.currentShift !== this.props.currentShift)
      && nextProps.pathname === this.props.pathname
    ) {
      return false;
    }

    return true;
  }

  render() {
    // const childrenWithProps = React.Children.map(this.props.children, child => React.cloneElement(child, {
    //   getHeaderHeight: this.props.getHeaderheight,
    //   getFooterHeight: this.props.getFooterHeight,
    //   apiInstance: this.props.apiInstance,
    //   onChangePopup: this.props.onChangePopup,
    // }));

    return <div>{this.props.children}</div>;
  }
}
