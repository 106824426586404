export default {
  hits: 'bestsellers',
  collections: 'collections',
  'collections/kandinsky': 'collections/kandinsky',
  textile: 'apparel-and-accessories',
  'textile/t-shirts-sw': 'apparel-and-accessories/tees-and-sweatshirts',
  'textile/totes-and-bags': 'apparel-and-accessories/bags',
  'textile/raincoats': 'apparel-and-accessories/raincoats-and-umbrellas',
  'textile/scarves-and-shawls': 'apparel-and-accessories/scarves-and-shawls',
  'textile/ties': 'apparel-and-accessories/ties-and-bowties',
  'textile/accessories2': 'apparel-and-accessories/accessories',
  'textile/e-accessories': 'apparel-and-accessories/accessories',
  'books-and-media': 'books',
  'books-and-media/hermitage-albums': 'books/hermitage-albums',
  'books-and-media/fashion': 'books/fashion',
  'books-and-media/design-books': 'books/design',
  'books-and-media/russian-history': 'books/russian-history-and-art',
  'books-and-media/foreign-culture': 'books/architecture-and-interior',
  'books-and-media/eastern-ancient': 'books/eastern-and-ancient-art',
  'books-and-media/fine-arts': 'books/fine-arts',
  reproductions: 'reproductions',
  'reproductions/impressionism-painting':
    'reproductions/impressionism-painting',
  'reproductions/classical-painting': 'reproductions/classical-painting',
  'reproductions/medieval-painting': 'reproductions/medieval-painting',
  'reproductions/austria-XVIII-XIX': 'reproductions/austria-XVIII-XIX',
  graphics: 'reproductions/graphics',
  'reproductions/graphics': 'reproductions/graphics',
  'reproductions/britain-xv-xix': 'reproductions/britain-xv-xix',
  'reproductions/germany-XVI-XX': 'reproductions/germany-XVI-XX',
  'reproductions/holland-XVII-XVIII': 'reproductions/holland-XVII-XVIII',
  'reproductions/spain-XV-XIX': 'reproductions/spain-XV-XIX',
  'reproductions/italy-XIII-XVIII': 'reproductions/italy-XIII-XVIII',
  'reproductions/netherlands-XV-XVI': 'reproductions/netherlands-XV-XVI',
  'reproductions/russia-XVIII-XIX': 'reproductions/russia-XVIII-XIX',
  'reproductions/flanders-XVII-XVIII': 'reproductions/flanders-XVII-XVIII',
  'reproductions/france-XV-XIX': 'reproductions/france-XV-XIX',
  'graphics/other-countries-graphics': 'reproductions/other-countries',
  decoration: 'home',
  'decoration/muggs-and-cups': 'home/tableware',
  'decoration/pillows-and-plaids': 'home/textile',
  'premium/decorative-plates': 'home/ceramics',
  'premium/amber-ware': 'home/amberware',
  'premium/exclbiju/amber-jewelry': 'jewelry',
  'decoration/vases': 'home',
  'jewelry/ear-rings': 'jewelry/earrings',
  'jewelry/rings': 'jewelry/rings',
  'jewelry/pendants': 'jewelry/pendants',
  'jewelry/murano-jewelry': 'jewelry/Murano-glass',
  'new-arrivals': 'new-arrivals',
  'office/notebooks-and-diaries': 'stationery/notebooks-diaries-sketchbooks',
  'office/stationery': 'stationery/writing-materials',
  'office/calendars': 'stationery/calendars',
  kids: 'kids',
  'books-and-media/books-kids': 'kids/kids-books',
  'premium/sculpture': 'sculpture',
  'premium/sculpture/bronze': 'sculpture/bronze',
  'premium/sculpture/marble': 'sculpture/marble',
  office: 'stationery',
  jewelry: 'jewelry',
};
