import React from 'react';
import styled, { css } from 'styled-components/macro';

const Svg = styled.svg`
  margin: ${props => (props.margin ? props.margin : '0')};
  width: 20px;

  @media screen and (min-width: 1200px) {
    width: ${props => props.width};
  }
`;

const Path = styled.path`
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
`;

const AnimatePath = styled(Path)`
  stroke-dasharray: ${props => props.dashArray};
  stroke-dashoffset: ${props => props.dashOffset};
  stroke: #e71d85;
  transition: stroke-dashoffset 0.7s;

  ${props => props.isHover
    && css`
      stroke-dashoffset: ${propsHovered => propsHovered.dashOffsetOnHover};
    `};
`;

export default class Ingerprint extends React.PureComponent {
  render() {
    return (
      <Svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 43 44"
        xmlSpace="preserve"
        width={this.props.width}
        margin={this.props.margin}
      >
        <Path d="M32,36v-7c0-6.1-4.9-11-11-11c-6.1,0-11,4.9-11,11v6" />
        <AnimatePath
          dashArray="48"
          dashOffset="48"
          dashOffsetOnHover="0"
          isHover={this.props.isHover}
          d="M32,36v-7c0-6.1-4.9-11-11-11c-6.1,0-11,4.9-11,11v6"
        />

        <Path d="M40.2,11C35.6,5.5,28.7,2,21,2C13.6,2,7,5.2,2.4,10.3" />
        <AnimatePath
          dashArray="43"
          dashOffset="43"
          dashOffsetOnHover="0"
          isHover={this.props.isHover}
          d="M40.2,11C35.6,5.5,28.7,2,21,2C13.6,2,7,5.2,2.4,10.3"
        />

        <Path d="M39,28c0-7.6-4.7-14.1-11.4-16.8" />
        <AnimatePath
          dashArray="22"
          dashOffset="22"
          dashOffsetOnHover="0"
          isHover={this.props.isHover}
          d="M39,28c0-7.6-4.7-14.1-11.4-16.8"
        />

        <Path d="M21,10c-9.9,0-18,8.1-18,18" />
        <AnimatePath
          dashArray="29"
          dashOffset="29"
          dashOffsetOnHover="58"
          isHover={this.props.isHover}
          d="M21,10c-9.9,0-18,8.1-18,18"
        />

        <Path d="M24,37.9V29c0-1.7-1.3-3-3-3c-1.7,0-3,1.3-3,3c0,0,0.2,3.9,0,5.9c-0.4,4.8-3,7.1-3,7.1" />
        <AnimatePath
          dashArray="33"
          dashOffset="33"
          dashOffsetOnHover="66"
          isHover={this.props.isHover}
          d="M24,37.9V29c0-1.7-1.3-3-3-3c-1.7,0-3,1.3-3,3c0,0,0.2,3.9,0,5.9c-0.4,4.8-3,7.1-3,7.1"
        />
      </Svg>
    );
  }
}
