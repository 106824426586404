import React from 'react';
import { CartAmount } from '../styled';

export default function renderCartAmount(products) {
  const amountItems = products.length;

  if (amountItems > 9) {
    return <CartAmount>9+</CartAmount>;
  }

  if (amountItems === 0) {
    return <CartAmount isDisabled />;
  }

  return <CartAmount>{amountItems}</CartAmount>;
}
