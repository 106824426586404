import minBy from 'lodash/minBy';

import getOptionValueByID from './getOptionValueByID';

export default function getMinQuantity(product, productActiveOptions) {
  if (
    product.options
    && product.options.length > 0
    && Object.keys(productActiveOptions).length === product.options.length
  ) {
    const activeOptions = Object.keys(productActiveOptions);

    const options = [];
    activeOptions.forEach((optionID) => {
      options.push(
        getOptionValueByID(productActiveOptions, product.options, optionID),
      );
    });

    const optionValueByMinimumQuantity = minBy(
      options,
      valueOption => valueOption.instockquantity || 99,
    );

    return optionValueByMinimumQuantity.instockquantity || 99;
  }

  return parseInt(product.instockquantity, 10);
}
