import React from 'react';
import styled from 'styled-components/macro';

import { addEventWheel } from '../../Utils';

import Main from './Main';

import pushEventOpenGA from '../pushEventOpenGA';

const Wrapper = styled.div`
  position: fixed;
  z-index: 27;
  left: 0;
  top: 0;
  width: 100%;
  height: ${(props) => props.searchHeight}px;
  transition: all 0.22s;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
`;

class Search extends React.Component {
  wrapperDOM = null;

  input = null;

  shouldComponentUpdate(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      return true;
    }

    return false;
  }

  componentDidUpdate() {
    const { isOpen } = this.props;

    pushEventOpenGA(isOpen, 'Search');

    if (isOpen) {
      setTimeout(() => this.input.focus(), 300);
      addEventWheel(true, this.wrapperDOM, this.onWheelOnOpened);
    } else {
      setTimeout(() => {
        this.input.value = '';
      }, 300);
      addEventWheel(false, this.wrapperDOM, this.onWheelOnOpened);
    }
  }

  onWheelOnOpened = (e) => {
    e.preventDefault();
  };

  render() {
    return (
      <Wrapper
        isOpen={this.props.isOpen}
        searchHeight={this.props.height}
        ref={(el) => {
          this.wrapperDOM = el;
        }}
      >
        <Main setRef={(input) => (this.input = input)} />
      </Wrapper>
    );
  }
}

export default Search;
