import FETCHED_CATEGORIES from './apiActions';

const initialPopups = {
  categories: [],
};

export default function popupReducer(state = initialPopups, action) {
  if (action.type === FETCHED_CATEGORIES) {
    return {
      ...state,
      categories: [...state.categories, ...action.payload],
    };
  }

  return state;
}
