export default function hasOptionsDiscount(options) {
  let hasDiscount = false;

  options.map(option => option.values.map((optionValue) => {
    if (
      optionValue.discount
        && parseInt(optionValue.discount, 10) > 0
        && parseInt(optionValue.instock, 10) > 0
    ) {
      hasDiscount = true;
    }

    return optionValue;
  }));

  return hasDiscount;
}
