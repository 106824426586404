import find from 'lodash/find';

export default function getOptionValueByID(activeOptions, options, id) {
  const option = find(
    options,
    eachOption => parseInt(eachOption.id, 10) === parseInt(id, 10),
  );

  const selectedOption = find(
    option.values,
    value => parseInt(value.id, 10) === parseInt(activeOptions[id], 10),
  );

  return selectedOption;
}
