import { handleActions } from 'redux-actions';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import reject from 'lodash/reject';
import assign from 'lodash/assign';
import unionBy from 'lodash/unionBy';

import {
  CHANGE_QUANTITY_PRODUCT,
  DELETE_FROM_CART,
  ADD_TO_CART,
  updateCartProductsLoading,
  updateCartProductsSuccess,
  updateCartProductsFailed,
} from './actions';

const initialState = {
  cartIsLoading: false,
  products: [],
};

if (typeof window !== 'undefined') {
  initialState.products = localStorage.getItem('cart')
    ? JSON.parse(localStorage.getItem('cart'))
    : [];
}

export default handleActions(
  {
    [ADD_TO_CART]: ({ products, ...rest }, action) => {
      if (isEmpty(products)) {
        const newProducts = products.concat(action.payload);
        const newState = { products: newProducts, ...rest };

        localStorage.setItem('cart', JSON.stringify(newProducts));
        return newState;
      }

      if (
        products.find((product) => product.cartID === action.payload.cartID)
      ) {
        let addNew = false;

        let newProducts = products.map((product) => {
          if (product.cartID === action.payload.cartID) {
            if (
              product.options &&
              Object.keys(product.options).length > 0 &&
              action.payload.options &&
              Object.keys(action.payload.options).length > 0
            ) {
              // with options
              if (isEqual(product.options, action.payload.options)) {
                const newQuantity = product.quantity + action.payload.quantity;

                if (newQuantity <= product.maxquantity) {
                  return {
                    ...product,
                    quantity: newQuantity,
                  };
                }

                alert(
                  'К сожалению, товара больше нет. Это последние экземпляры.',
                );

                return product;
              }

              addNew = true;
              return product;
            }

            // without options

            const newQuantity = product.quantity + action.payload.quantity;

            if (newQuantity <= product.maxquantity) {
              return {
                ...product,
                quantity: newQuantity,
              };
            }

            alert('К сожалению, товара больше нет. Это последние экземпляры.');

            return product;
          }
          return product;
        });

        if (addNew) {
          newProducts = products.concat(action.payload);
        }

        if (window.rrApi) {
          window.rrApi.addToBasket(action.payload.id);
        }

        const newState = { products: newProducts, ...rest };

        localStorage.setItem('cart', JSON.stringify(newProducts));

        return newState;
      }

      const newProducts = unionBy(products, [action.payload]);
      const newState = { products: newProducts, ...rest };

      if (window.rrApi) {
        window.rrApi.addToBasket(action.payload.id);
      }

      localStorage.setItem('cart', JSON.stringify(newProducts));
      return newState;
    },
    [CHANGE_QUANTITY_PRODUCT]: ({ products, ...rest }, action) => {
      const newProducts = products.map((product) => {
        if (product.cartID && product.cartID === action.payload.cartID) {
          return assign({}, product, action.payload);
        }

        return product;
      });

      const newState = { products: newProducts, ...rest };

      localStorage.setItem('cart', JSON.stringify(newProducts));

      return newState;
    },
    [DELETE_FROM_CART]: ({ products, ...rest }, action) => {
      const newProducts = reject(
        products,
        (product) => product.cartID === action.payload,
      );

      const newState = { products: newProducts, ...rest };

      localStorage.setItem('cart', JSON.stringify(newProducts));

      return newState;
    },
    [updateCartProductsLoading]: (state, { payload }) => ({
      ...state,
      cartIsLoading: payload,
    }),
    [updateCartProductsSuccess]: (state, { payload }) => ({
      ...state,
      products: payload,
    }),
    [updateCartProductsFailed]: (state, { payload }) => ({
      ...state,
      error: payload,
    }),
  },
  initialState,
);
