import styled from 'styled-components/macro';

const Button = styled.button`
  display: inline-block;
  background-color: ${props => (props.isDisabled ? '#F0F0F0' : '#e71d85')};
  border: none;
  font-size: 11px;
  font-weight: 500;
  color: ${props => (props.isDisabled ? '#000000' : '#fff')};
  padding: ${props => (props.paddingMobile
    ? props.paddingMobile
    : props.padding
      ? props.padding
      : '15px 25px')};
  cursor: ${props => (props.isDisabled ? 'default' : 'pointer')};
  outline: none;
  transition: all 0.18s;

  &:hover {
    background-color: ${props => (props.isDisabled ? '#F0F0F0' : '#dc5c9e')};
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    padding: ${props => (props.padding ? props.padding : '15px 25px')};
  }
`;

export default Button;
