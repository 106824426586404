import React from 'react';
import styled, { css } from 'styled-components/macro';

const Svg = styled.svg`
  margin: ${props => (props.margin ? props.margin : '')};
  width: 18px;

  @media screen and (min-width: 1200px) {
    width: ${props => props.width};
  }
`;

const Circle = styled.circle``;

const CircleAnimate = styled(Circle)`
  stroke-dasharray: ${props => props.dashArray};
  stroke-dashoffset: ${props => props.dashOffset};
  stroke: #e71d85;
  transition: stroke-dashoffset 0.6s;

  ${props => props.isHover
    && css`
      stroke-dashoffset: ${propsHovered => propsHovered.dashOffsetOnHover};
    `};
`;

const Path = styled.path``;

const PathAnimate = styled.path`
  stroke-dasharray: ${props => props.dashArray};
  stroke-dashoffset: ${props => props.dashOffset};
  stroke: #e71d85;
  transition: stroke-dashoffset 0.3s;

  ${props => props.isHover
    && css`
      stroke-dashoffset: ${propsHovered => propsHovered.dashOffsetOnHover};
    `};
`;

export default class SearchIcon extends React.PureComponent {
  render() {
    return (
      <Svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
        width={this.props.width}
        margin={this.props.margin}
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          transform="translate(1 1)"
        >
          <Circle cx="8" cy="8" r="8" />
          <CircleAnimate
            cx="8"
            cy="8"
            r="8"
            dashArray="51"
            dashOffset="51"
            dashOffsetOnHover="0"
            isHover={this.props.isHover}
          />
          <Path d="M20 20l-6-6" />
          <PathAnimate
            d="M20 20l-6-6"
            dashArray="9"
            dashOffset="9"
            dashOffsetOnHover="0"
            isHover={this.props.isHover}
          />
        </g>
      </Svg>
    );
  }
}
