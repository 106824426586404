import { createActions } from 'redux-actions';

import { getActualProductsInfo } from 'api/products';
import { getIdsQuery, updateProduct } from './lib/helpers';
import { setNewUpdateTimestamp } from './lib/cartUpdateTimeoutService';

export const CHANGE_QUANTITY_PRODUCT = 'CHANGE_QUANTITY_PRODUCT';
export const ADD_TO_CART = 'ADD_TO_CART';
export const DELETE_FROM_CART = 'DELETE_FROM_CART';

export const {
  updateCartProductsLoading,
  updateCartProductsSuccess,
  updateCartProductsFailed,
} = createActions(
  'UPDATE_CART_PRODUCTS_LOADING',
  'UPDATE_CART_PRODUCTS_SUCCESS',
  'UPDATE_CART_PRODUCTS_FAILED',
);

export const updateCartProducts = () => async (dispatch, getState) => {
  let loaderIsShowed = false;
  const loaderTimeout = setTimeout(() => {
    loaderIsShowed = true;
    dispatch(updateCartProductsLoading(true));
  }, 300);

  const { products } = getState().cart;

  try {
    const ids = products.map((el) => el.id);
    const idsQuery = getIdsQuery(ids);

    const res = await getActualProductsInfo(idsQuery);

    if (res.status !== 200) throw res;

    const actualData = res.data;
    const newProducts = products
      .map((el) => updateProduct(el, actualData))
      .filter((el) => el !== null);
    dispatch(updateCartProductsSuccess(newProducts));

    setNewUpdateTimestamp();
  } catch (e) {
    dispatch(updateCartProductsSuccess(products));
  }
  clearTimeout(loaderTimeout);

  if (loaderIsShowed) {
    dispatch(updateCartProductsLoading(false));
  }
};
