import React from 'react';
import styled from 'styled-components/macro';

import { Button, Modal } from '../UI';

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 31px;
  font-weight: 700;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  font-size: 17px;
  font-weight: 500;
`;

const Info = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 60px;
`;

// const BrandColor = styled.span`
//   color: #e71d85;
// `;

export default class Cookies extends React.Component {
  componentDidMount() {}

  render() {
    const { isOpen, onClose, openingPoint } = this.props;
    return (
      <Modal isOpen={isOpen} onClose={onClose} openingPoint={openingPoint}>
        <>
          <Title>Использование Cookies</Title>
          <Description>Ознакомьтесь</Description>

          <Info>
            <strong>Что такое файлы COOKIE?</strong>
            <br />
            <br />
            Файлы cookie – это небольшие файлы, которые обычно состоят из букв и
            цифр. Cookie-файл может отправляться в браузер вашего ПК или другого
            устройства каждый раз, когда вы посещаете определенные сайты. Цель
            файлов cookie – хранить базовую информацию, такую как предпочтения
            посетителей. Cookie будут отправляться обратно на первоначальный
            сайт при каждом вашем последующем посещении этого сайта. Cookie
            полезны, так как позволяют сайтам в течение некоторого периода
            времени распознавать ваш браузер или устройство.
            <br />
            Большинство интернет-браузеров поддерживает cookie. Пользователи
            могут управлять браузерами, настроив их так, чтобы они отклоняли
            определенные типы файлов cookie или показывали, когда cookie
            отправляются. Большинство самых известных в мире сайтов использует
            cookie. Без файлов cookie функции или услуги, доступные на некоторых
            сайтах, могут даже не работать должным образом.
            <br />
            Какие типы файлов cookie мы используем?
            <br />
            <br />
            При посещении нашего сайта могут использоваться два типа cookie:
            <br />
            <br />
            — Основные cookie, которые устанавливаются конкретным сайтом и могут
            быть прочитаны только этим сайтом. Например, когда файл cookie с
            нашего сайта передается в ваш браузер, только наш сайт сможет его
            распознать.
            <br />
            — Сторонние cookie, которые устанавливаются не нашей компанией, а,
            например, сторонней аналитической компанией, которая передает в ваш
            браузер собственные файлы cookie.
            <br />
            В таблице ниже приведен список файлов cookie, которые мы используем,
            их тип, цель и длительность хранения.
            <br />
            <br />
            Пожалуйста, учтите, что сторонние cookie управляются
            соответствующими партнерами и поставщиками услуг, как указано в
            таблице выше. У нас нет ни доступа к файлам cookie наших партнеров,
            ни возможности их контролировать. Вам нужно ознакомиться с политикой
            в отношении файлов cookie соответствующей сторонней компании, чтобы
            узнать больше об использовании информации, которую они собирают.
            <br />
            <br />
            <strong>Зачем мы используем файлы cookie?</strong>
            <br />
            <br />
            Мы используем cookie, чтобы лучше понимать, как вы взаимодействуете
            с материалами на нашем сайте. Кроме того, эти небольшие файлы могут
            дополнить ваши впечатления от использования, так как запоминают ваши
            личные предпочтения. Cookie помогают нам считать, сколько
            пользователей посещает определенные страницы нашего сайта. Еще одна
            причина использования файлов cookie – показывать вам материалы,
            подобранные под ваши конкретные интересы. В частности, они помогают
            изучать ваше поведение, чтобы показывать вам таргетированную рекламу
            на сторонних сайтах с целью подбора товаров и услуг под ваши
            индивидуальные потребности.
            <br />
            <br />
            <strong>Когда мы используем файлы cookie?</strong>
            <br />
            <br />
            Любой браузер, загрузивший наш сайт, получит cookie. Вы всегда
            можете отказаться от обоих типов cookie на нашем сайте, изменив у
            себя настройки файлов cookie. Инструкция по отключению файлов cookie
            приведена ниже.
            <br />
            <br />
            <strong>Как отключить и/или удалить файлы cookie?</strong>
            <br />
            <br />
            Прежде всего, пожалуйста, учтите, что большинство браузеров
            принимает cookie по умолчанию. Вы имеете право принять или отклонить
            передачу файлов cookie на ваш ПК или другое устройство, в любое
            время изменив настройки в вашем браузере в соответствии с вашими
            предпочтениями в отношении файлов cookie. Вы можете контролировать
            и/или удалять файлы cookie по вашему усмотрению. Чтобы узнать об
            этом больше, посетите, пожалуйста,
            {' '}
            <a
              href="https://aboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              aboutcookies.org
            </a>
            . Вы можете удалить все файлы cookie, уже имеющиеся на вашем ПК или
            другом устройстве, и настроить большинство браузеров на отказ
            принимать файлы cookie. Однако в этом случае вам придется вручную
            корректировать некоторые установки при каждом посещении сайта. Из-за
            этого некоторые сервисы и функции могут не работать должным образом.
            <br />
            <br />
            Для отказа от отслеживания сервисом Google Analytics на всех сайтах
            посетите
            <a
              href="http://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://tools.google.com/dlpage/gaoptout
            </a>
            {/* <PointWrap>
          <PointNum>1.</PointNum>
          <Point />
        </PointWrap>

        <PointWrap>
          <PointNum>2.</PointNum>
          <Point />
        </PointWrap> */}
          </Info>

          <ButtonWrap>
            <Button padding="17px 70px" onClick={onClose}>
              Всё понятно
            </Button>
          </ButtonWrap>
        </>
      </Modal>
    );
  }
}
