import axios from 'axios';

const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://dev.hermitageshop.ru/api/v2'
    : '/api/v2';

export default axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: 'Basic dGVzdDp0ZXN0MTIzNDU2',
  },
});
