import React from 'react';
import styled from 'styled-components';
import { Link as RLink } from 'react-router-dom';

const BrandColor = styled.span`
  color: #e71d85;
`;

const Link = styled(RLink)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  border-bottom: 1px solid #e71d85;
  transition: all 0.1s;

  &:hover {
    border-color: transparent;
  }
`;

export default () => (
  <>
    <p>
      <BrandColor>
        <strong>Покупка ваучеров на организацию посещения Эрмитажа</strong>
      </BrandColor>
    </p>
    <p>&nbsp;</p>
    <p>
      Приобрести электронные входные ваучеры на организацию посещения музея вы
      можете в разделе{' '}
      <Link to="/tickets" href="/tickets">
        <BrandColor>&laquo;Посещение музея&raquo;</BrandColor>
      </Link>
      .&nbsp;Оплата ваучеров осуществляется с помощью банковской карты онлайн. К
      оплате принимаются карты VISA, Mastercard, МИР.
    </p>
    <p>Обратите внимание:</p>
    <p>
      <strong>&mdash;&nbsp;при покупке ваучера для другого человека</strong>,
      вам необходимо при вводе данных о посетителе указать фамилию, имя и
      отчество того, кто непосредственно собирается посетить музей.
    </p>
    <p>
      <strong>
        &mdash;&nbsp;при покупке ваучеров для нескольких посетителей
      </strong>
      , вам необходимо при вводе данных указать только фамилию, имя и отчество
      одного из посетителей.
    </p>
    <br />
    <br />
    <p>
      <BrandColor>
        <strong>Типы ваучеров</strong>
      </BrandColor>
    </p>
    <br />
    <p>
      <strong>&mdash; Однодневный ваучер</strong>
    </p>
    <p>
      Однодневный ваучер позволит вам посетить все экспозиции музейного
      комплекса, расположенные на Дворцовой площади (Главный музейный комплекс и
      Главный штаб).
    </p>
    <p>
      Для посетителей Главного музейного комплекса предусмотрен
      отдельный/специальный вход со стороны Миллионной улицы через Шуваловский
      проезд, соединяющий Миллионную улицу с Дворцовой набережной между зданиями
      Нового и Малого Эрмитажа (рядом с Атлантами).
    </p>
    <p>
      Проход в Главный штаб осуществляется через общий вход для посетителей со
      стороны Дворцовой площади.
    </p>
    <p>
      <strong>
        Срок действия однодневного ваучера – 1 день с момента первого прохода.
      </strong>
    </p>
    <br />
    <p>
      <strong>&mdash; Двухдневный ваучер</strong>
    </p>
    <p>Двухдневный ваучер позволит вам посетить:</p>
    <ul>
      <li>
        Все экспозиции музейного комплекса, расположенные на Дворцовой площади
        (Главный музейный комплекс и Главный штаб);
      </li>
      <li>
        Зимний дворец Петра I – вход для посетителей с Дворцовой набережной, д.
        32;
      </li>
      <li>
        Дворец Меншикова – вход для посетителей с Университетской набережной, д.
        15;
      </li>
      <li>
        Музей Императорского фарфорового завода – вход для посетителей с пр.
        Обуховской обороны, д. 153.
      </li>
    </ul>
    <p>
      Для посетителей Главного музейного комплекса предусмотрен
      отдельный/специальный вход со стороны Миллионной улицы через Шуваловский
      проезд, соединяющий Миллионную улицу с Дворцовой набережной между зданиями
      Нового и Малого Эрмитажа (рядом с Атлантами).
    </p>
    <p>
      Проход в остальные экспозиционные объекты осуществляется через общий вход
      для посетителей.
    </p>
    <p>
      <strong>
        Срок действия двухдневного ваучера -  2 последовательных дня с момента
        первого прохода
      </strong>{' '}
      (исключая понедельники, первое января и девятое мая – дни, когда музей не
      работает).{' '}
      <i>
        Например, если ваш второй календарный день посещения приходится на один
        из перечисленных выше дней, вы можете посетить музей в день, следующий
        за выходным днем музея: соответственно, во вторник; второго января;
        десятого мая.
      </i>
    </p>
    <p>
      Внимание! Любой тип ваучера дает право на посещение любого экспозиционного
      объекта только один раз в течение выбранного вами дня.
    </p>
    <br />
    <br />
    <p>
      <BrandColor>
        <strong>Детские билеты</strong>
      </BrandColor>
    </p>
    <br />
    <p>
      Приобретая ваучеры на организацию посещения музея, вы можете добавить к
      каждому заказу 
      <strong>до двух бесплатных билетов на детей до 16 лет</strong>.
    </p>
    <p>
      Обратите внимание, что при оформлении заказа, в котором есть бесплатные
      детские билеты, информация о них будет указана только{' '}
      <strong>в основном ваучере (в первом ваучере из заказа)</strong>, который
      вы получите по электронной почте. Отдельные бесплатные детские билеты не
      формируется электронным способом, и вам{' '}
      <strong>
        будет необходимо получить их непосредственно при посещении музея
      </strong>
      . Для этого необходимо обратиться в Интернет-кассу Главного музейного
      комплекса или в кассу посещаемого Вами другого экспозиционного объекта
      (точную информацию о нахождении кассы можно получить у администратора
      входной зоны) и назвать номер заказа. При себе необходимо иметь любой
      документ, подтверждающий возраст ребенка.
    </p>
    <br />
    <br />
    <p>
      <BrandColor>
        <strong>Использование ваучеров</strong>
      </BrandColor>
    </p>
    <br />
    <p>
      Обратите внимание, что в настоящее время входные ваучеры можно приобрести
      со сроком действия только до 31 декабря 2019 года.
    </p>
    <p>
      Входные ваучеры, приобретенные начиная с 01 января 2020 года, будут
      <strong>
        действительны в течение девяноста дней с даты заказа и оплаты
      </strong>
      .
    </p>
    <p>
      Полученные на электронную почту 
      <strong>
        однодневные ваучеры, вы можете распечатать или сохранить в электронном
        виде
      </strong>{' '}
      на мобильном устройстве.
    </p>
    <p>
      <strong>Двухдневные ваучеры необходимо распечатать</strong>, так как не во
      всех экспозиционных комплексах Эрмитажа есть считывающие устройства для
      электронного штрих-кода.
    </p>
    <p>
      Для прохода в музей, необходимо приложить штрих-код на ваучере к
      считывающему устройству на турникете.
    </p>
    <p>
      Обратите внимание: 
      <strong>
        все ваучеры из одного заказа должны быть использованы при одном
        посещении музея
      </strong>
      , т.е. нельзя использовать один ваучер из заказа в один день, а другой – в
      другой день.
    </p>
    <br />
    <br />
    <p>
      <BrandColor>
        <strong>Отмена и возврат ваучеров</strong>
      </BrandColor>
    </p>
    <br />
    <p>Если Ваши планы изменились, вы можете отменить заказ ваучера.</p>
    <p>
      Для отмены заказа вам необходимо отправить письмо на адрес электронной
      почты Службы поддержки info@hermitageshop.com с указанием следующих
      данных:
      <span style={{ color: '#000000' }}>
        <strong>info@hermitageshop.com&nbsp;</strong>
      </span>
      с указанием следующих данных:
    </p>
    <ul>
      <li>номер заказа;</li>
      <li>фамилия, имя и отчество человека, на которого оформлен заказ;</li>
      <li>номера телефона, указанный при оформлении заказа;</li>
      <li>причина отмены заказа.</li>
    </ul>
    <br />
    <p>
      В отношении интернет-ваучеров применяются следующие правила аннулирования
      оформленных заказов:
    </p>
    <ul>
      <li>
        Полный возврат средств по заказу возможен в{' '}
        <strong>течение 5 дней</strong> со дня оформления заказа.
      </li>
      <li>
        По истечении пятидневного срока со дня оформления заказа, возврат
        средств осуществляется за вычетом стоимости обработки заказа – 
        <strong>130 руб.</strong> за каждого указанного человека в однодневном
        ваучере и <strong>150 руб.</strong> за каждого указанного человека в
        двухдневном ваучере. В стоимость обработки заказа входят, в том числе,
        комиссии банков и платежных систем.
      </li>
      <li>
        При необходимости переоформления заказ на другой временной период,
        стоимость обработки заказа не удерживается.
      </li>
      <li>
        Возврат средств возможен только до 31 декабря 2019 года в соответствии с
        текущим сроком действия ваучера, и в течение <strong>90 дней</strong> с
        момента оформления заказа, начиная с 01 января 2020 года;
      </li>
      <li>
        <strong>Частичная отмена заказа невозможна</strong>, - возврат средств
        можно осуществить только за всех людей, указанных в одном заказе;
      </li>
      <li>
        Возврат производится обратной транзакцией на карту, которая была
        использована при оплате.
      </li>
    </ul>
    <br />
    <br />
    <p>
      <BrandColor>
        <strong>Экскурсии</strong>
      </BrandColor>
    </p>
    <br />
    <p>
      При заказе входных ваучеров на посещение музея, вы можете также заказать
      экскурсии в Золотую и Бриллиантовую кладовые.
    </p>
    <p>
      Заказ экскурсии онлайн позволит вам забронировать место в экскурсионной
      группе заранее и избежать очереди в кассу за экскурсионной путевкой.
    </p>
    <p>
      Экскурсионный ваучер дает право на посещение групповой экскурсии в
      указанные в ваучере день и время.
    </p>
    <p>
      Обратите внимание: заказ экскурсии онлайн возможен только при заказе
      входных ваучеров на посещение музея. Количество участников экскурсии в
      одном заказе не может превышать количество входных ваучеров в этом заказе.
    </p>
    <p>
      При заказе двух экскурсий вы получите экскурсионный ваучер на каждого
      посетителя на каждую из экскурсий.
    </p>
    <p>
      Каждый экскурсионный ваучер имеет индивидуальный штрих-код, который
      необходимо отсканировать на входе в Кладовую. Полученные экскурсионные
      ваучеры можно распечатать или сохранить на мобильных устройствах.
    </p>
    <p>
      Бесплатный детский экскурсионный билет при заказе экскурсий онлайн не
      предусмотрен.
    </p>
    <p>
      Заказ экскурсий онлайн возможен не позднее, чем за{' '}
      <strong>1 день до начала экскурсии</strong>.
    </p>
    <p>
      Сбор экскурсионной группы производится за 10 минут до начала экскурсии.
      Каждая экскурсия длится 2 академических часа. Размер группы не должен
      превышать 16 человек.
    </p>
    <p>
      В случае опоздания участника экскурсии к месту сбора экскурсионной групп
      по своей вине, услуга по организации экскурсии считается оказанной.
    </p>
    <p>Место сбора экскурсионных групп:</p>
    <ul>
      <li>Для экскурсии в Золотую Кладовую: у Церковного контроля;</li>
      <li>Для экскурсии в Бриллиантовую кладовую: у Главного контроля.</li>
    </ul>
    <p>
      Информацию о проходе к месту сбора экскурсионных групп можно получить у
      администратора на стойке информации до прохода на экспозицию или по
      телефону администратора музея (812) 710-90-79, или же в Службе поддержки –
      телефон 8(812) 429-74-07.
    </p>
    <p>
      <strong>Проход к месту сбора экскурсии в</strong> Золотую кладовую и
      Бриллиантовые Кладовые:
    </p>
    <p>
      Необходимо войти в музей через отдельный/специальный вход со стороны
      Миллионной улицы через Шуваловский проезд, соединяющий Миллионную улицу с
      Дворцовой набережной между зданиями Нового и Малого Эрмитажа (рядом с
      Атлантами).
    </p>
    <p>
      При наличии очереди на отдельном/специальном входе необходимо предъявить
      экскурсионный ваучер сотруднику музея на входе, в этом случае для Вас
      будет организован внеочередной проход.
    </p>
    <p>
      В связи со сложным логистическим устройством Эрмитажа мы не рекомендуем
      заказывать экскурсии в Золотую и Бриллиантовую кладовую в течение одного
      дня. В случае, если Вы все-таки заказали обе экскурсии в течение одного
      дня, предлагаем Вам ознакомится со следующей информацией, которую в
      последующем (при посещении музея) рекомендуем иметь в распечатанном виде:
    </p>
    <p>
      По условиям заказа экскурсионных ваучеров первой по времени всегда будет
      осуществляться экскурсия в Золотую кладовую. Таким образом, для
      дальнейшего участия в экскурсии в Бриллиантовую кладовую после окончания
      экскурсии в Золотую кладовую, Вам необходимо от места сбора экскурсии в
      Золотую кладовую проследовать к месту сбора в Бриллиантовую кладовую.{' '}
    </p>
    <p>
      Далее всю информацию (в случае необходимости) сверяйте у музейных
      смотрителей:
    </p>
    <p>
      <strong>
        Проход (от Шуваловского проезда) к месту сбора экскурсии в Золотую
        кладовую:
      </strong>
    </p>
    <ul>
      <li>
        после прохождения контроля поднимитесь по лестнице в переход между
        зданиями (Сивков переход);
      </li>
      <li>поверните налево, пройдите через зал Египта (№ 100) до конца;</li>
      <li>
        поверните направо и через зону кафе/магазинов пройдите до Иорданской
        лестницы (Главная/Парадная лестница музея);
      </li>
      <li>
        поднимитесь на второй этаж, пройдите по Восточной галерее с портретами
        царской семьи (№ 151; 153) до Церковной лестницы, расположенной слева;
      </li>
      <li>спуститесь на первый этаж, поверните направо к почте;</li>
      <li>
        место сбора в Золотую кладовую находится за почтой перед турникетами
        Церковного контроля и обозначено информационными указателями.{' '}
      </li>
    </ul>
    <p>
      <strong>Проход к месту сбора экскурсии в Бриллиантовую кладовую:</strong>
    </p>
    <ul>
      <li>
        после прохождения контроля поднимитесь по лестнице в переход между
        зданиями (Сивков переход);
      </li>
      <li>поверните налево, пройдите через зал Египта (№ 100) до конца;</li>
      <li>
        поверните направо и следуйте через зону кафе/магазинов до Иорданской
        лестницы (Главная/Парадная лестница музея);
      </li>
      <li>пройдите налево по галерее к стойке администратора до турникетов;</li>
      <li>
        место сбора в Бриллиантовую кладовую обозначено информационными
        указателями.
      </li>
    </ul>
    <p>
      <strong>
        Проход к месту сбора экскурсии в Бриллиантовую кладовую от места сбора в
        Золотую кладовую:
      </strong>
    </p>
    <ul>
      <li>
        от места сбора экскурсии в Золотую кладовую (Церковный
        контроль) поверните налево и пройдите до Церковной лестницы; 
      </li>
      <li>
        поднимитесь на второй этаж и войдите в Восточную галерею с портретами
        царской семьи (№ 151; 153); 
      </li>
      <li>
        поверните направо, пройдите прямо по галерее до Иорданской лестницы;  
      </li>
      <li>
        спуститесь на первый этаж и пройдите вперед. Место сбора экскурсии в
        Бриллиантовую кладовую находится слева от Вас, до турникетов, рядом с
          пунктом выдачи аудиогидов и обозначено информационными указателями.
      </li>
    </ul>
    <p>
      Если после окончания экскурсий в Бриллиантовую и Золотую кладовую Вы
      хотите продолжить посещение музея, и принимая во внимание, что Ваш ваучер
      позволяет только однократно посетить все экспозиции Главного
      музейного комплекса, <strong>НЕ ВЫХОДИТЕ</strong> через турникет. 
    </p>
    <br />
    <br />
    <p>
      <BrandColor>
        <strong>Отмена и возврат экскурсионных ваучеров</strong>
      </BrandColor>
    </p>
    <br />
    <p>
      Если вы по каким-либо причинам передумали посетить экскурсию по
      приобретенному на сайте экскурсионному ваучеру, вы можете отменить заказ.
    </p>
    <p>
      Для отмены заказа вам необходимо отправить письмо на адрес электронной
      почты Службы поддержки info@hermitageshop.com с указанием следующих
      данных:
    </p>
    <ul>
      <li>номера заказа;</li>
      <li>Фамилии, имени и отчества человека, на которого оформлен заказ;</li>
      <li>Номера телефона, указанного при оформлении заказа;</li>
      <li>причины отмены заказа</li>
    </ul>
    <p>&nbsp;</p>
    <p>
      В отношении экскурсионных ваучеров применяются следующие правила
      аннулирования оформленных заказов:
    </p>
    <ul>
      <li>
        Возврат средств за экскурсионные ваучеры возможен не позднее трех дней
        до даты проведения экскурсии;
      </li>
      <li>
        Возврат средств за неиспользованный экскурсионный ваучер после
        наступления даты и времени заказанной экскурсии 
        <strong>не осуществляется</strong>;
      </li>
      <li>
        <strong>Частичная отмена заказа невозможна</strong>: возврат средств
        можно осуществить только за все ваучеры (входные и экскурсионные) в
        заказе;
      </li>
      <li>
        Возврат средств возможен только до 31 декабря 2019 года в соответствии с
        текущим сроком действия входного ваучера, и в течение 
        <strong>90 дней</strong> с момента оформления заказа, начиная с 01
        января 2020 года;
      </li>
      <li>
        Возврат производится обратной транзакцией на карту, которая была
        использована при оплате.
      </li>
    </ul>
    <br />
    <br />
    <p>
      <BrandColor>
        <strong>График работы Эрмитажа</strong>
      </BrandColor>
    </p>
    <br />
    <p>
      <strong>Понедельник &mdash; выходной</strong>
    </p>
    <p>
      Вторник 10:30 &ndash; 18:00
      <br />
      Среда 10:30 &ndash; 21:00
      <br />
      Четверг 10:30 &ndash; 18:00
      <br />
      Пятница 10:30 &ndash; 21:00
      <br />
      Суббота 10:30 &ndash; 18:00
      <br />
      Воскресенье 10:30 &ndash; 18:00
    </p>
    <p>Кассы закрываются на час раньше музея.</p>
    <p>
      {/* Праздничные дни: 10.30 &ndash; 18.00
      <br />
      Предпраздничные дни: 10.30 &ndash; 17.00
      <br /> */}
      1 января и 9 мая &ndash; музей закрыт
    </p>
    <p>
      Днями бесплатного посещения музея для всех категорий индивидуальных
      посетителей являются:
    </p>
    <p>
      &ndash; третий четверг каждого месяца
      <br />
      &ndash; 7 декабря – День Святой Екатерины, день рождения Эрмитажа;
      <br />
      &ndash; 18 мая – День музеев.
    </p>
    <p>
      Обратите, пожалуйста, внимание, что в указанные дни очереди в музей
      многократно увеличиваются.
    </p>
    <p>
      К сожалению, мы также не можем гарантировать для посетителей с ваучерами
      отсутствие очередей на входе в Главный музейный комплекс в первой половине
      дня, особенно во вторник, в праздничные дни и дни школьных каникул.
      Планируйте время своего посещения заранее.
    </p>
    <p>
      Обратите внимание, что в исключительных ситуациях, по решению руководства
      Эрмитажа, один или несколько экспозиционных комплексов могут быть закрыты
      для посещения в официальные часы своей работы. Следите за информацией на
      сайте Государственного Эрмитажа (https://www.hermitagemuseum.org) и на
      сайте Интернет-магазина (https://www.hermitageshop.ru).
    </p>
    <br />
    <p>
      <BrandColor>
        <strong>
          Адреса экспозиционных комплексов Государственного Эрмитажа:
        </strong>
      </BrandColor>
    </p>
    <br />
    <p>Главный музейный комплекс: Дворцовая площадь, д. 2.</p>
    <p>Главный штаб: Дворцовая площадь, д. 6/8</p>
    <p>Зимний дворец Петра I: Дворцовая набережная, д. 32</p>
    <p>Дворец Меншикова: Университетская набережная, д. 15</p>
    <p>
      Музей Императорского фарфорового завода: пр. Обуховской обороны, д. 153
    </p>
    <ul>
      <li>
        Для посетителей Главного музейного комплекса c входными ваучерами
        предусмотрен отдельный вход со стороны Миллионной улицы через
        Шуваловский проезд (внутриквартальный проезд, идущий от Дворцовой
        набережной до Миллионной улицы между зданиями Нового и Малого Эрмитажа).
      </li>
      <li>
        Для прохода в остальные экспозиционные комплексы необходимо пользоваться
        общим входом для посетителей.
      </li>
      <li>
        Для участников групповой экскурсии предусмотрен отдельный вход со
        стороны Миллионной улицы через Шуваловский проезд (внутриквартальный
        проезд, идущий от Дворцовой набережной до Миллионной улицы между
        зданиями Нового и Малого Эрмитажа).
      </li>
    </ul>
    <p>
      Если у вас возникли вопросы, связанные с работой музея, или интересует
      информация о дополнительных услугах для посетителей, пожалуйста, свяжитесь
      со справочной службой по телефону{' '}
      <a href="tel:+78124297407" style={{ color: '#e71d85' }}>
        <strong>+7 (812) 429-74-07</strong>
      </a>
    </p>
  </>
);
