import React from 'react';
import styled from 'styled-components/macro';
import { Grid, Row, Col } from 'react-flexbox-grid';

import {
  BottomWrapper as Wrapper,
  Documents,
  // LinkBottom as Link,
  PointBottom as Point,
  Copyright,
  Settings,
  RegionWrapper,
  SettingsTitle,
  Region,
  LanguageWrapper,
  Language,
  Flag,
  RegionName,
  LanguageTag,
  LanguageName,
  FakeLinkLinkBottom as FakeLink,
} from './styled';

import Hermitage from './Hermitage';

import russiaFlag from './flags/russia@3x.png';

const HermitageMobile = styled.div`
  display: flex;
  max-width: 160px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 80px;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const MobileLine = styled.div`
  height: 1px;
  background-color: #ededed;
  margin: 0 30px;
  margin-top: 30px;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

export default class Bottom extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.homePage !== this.props.homePage) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <Wrapper homePage={!!this.props.homePage}>
        <Grid fluid={!!this.props.homePage}>
          <Row>
            <Col xs={12} xl={9}>
              <Documents homePage={!!this.props.homePage}>
                <Point>
                  <FakeLink
                    onClick={e => this.props.onChangeModal({
                      name: 'personalData',
                      openingPoint: {
                        x: e.clientX,
                        y: e.clientY,
                      },
                    })
                    }
                  >
                    Обработка персональных данных
                  </FakeLink>
                </Point>

                <Point>
                  <FakeLink
                    onClick={e => this.props.onChangeModal({
                      name: 'cookies',
                      openingPoint: {
                        x: e.clientX,
                        y: e.clientY,
                      },
                    })
                    }
                  >
                    Использование Cookies
                  </FakeLink>
                </Point>

                <Point>
                  <FakeLink
                    onClick={e => this.props.onChangeModal({
                      name: 'oferta',
                      openingPoint: {
                        x: e.clientX,
                        y: e.clientY,
                      },
                    })
                    }
                  >
                    Публичная оферта
                  </FakeLink>
                </Point>

                {/* <Point>
                  <FakeLink
                    onClick={() => this.props.onOpenModal('isOpenPersonalData')}
                  >
                    Обработка персональных данных
                  </FakeLink>
                </Point> */}

                {/* <Point>
                  <Link to="/corpclients" href="/corpclients">
                    Корпоративным клиентам
                  </Link>
                </Point> */}
              </Documents>

              <Copyright>
                © Государственный Эрмитаж | © Museum Online, Inc. | 2003 —
                {' '}
                {new Date().getFullYear()}
                {' '}
г. | Все права защищены
              </Copyright>

              <MobileLine />

              <HermitageMobile>
                <Hermitage mobile />
              </HermitageMobile>
            </Col>

            <Col xs={3} className="hidden-lg hidden-md hidden-sm hidden-xs">
              <Settings>
                <RegionWrapper>
                  <SettingsTitle>Вы находитесь здесь:</SettingsTitle>

                  <Region>
                    <Flag src={russiaFlag} />
                    <RegionName>Россия</RegionName>
                  </Region>
                </RegionWrapper>

                <LanguageWrapper>
                  <SettingsTitle>Язык:</SettingsTitle>

                  <Language>
                    <LanguageTag>RU</LanguageTag>
                    <LanguageName>Русский</LanguageName>
                  </Language>
                </LanguageWrapper>
              </Settings>
            </Col>
          </Row>
        </Grid>
      </Wrapper>
    );
  }
}
