// only desktop component
import React from 'react';
import styled from 'styled-components/macro';
import { Link as RLink } from 'react-router-dom';
import { parseRelativePathSlug, getMainCategories } from '../../Utils';

import HorizontalScroll from '../HorizontalScroll';

const Wrapper = styled.div`
  /* border-bottom: 1px solid #ededed; */
  position: relative;
  overflow: hidden;
  display: none;

  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ededed;
  }

  @media screen and (min-width: 1200px) {
    display: block;
  }
`;

const Category = styled(RLink)`
  color: ${props => (props.isActive ? '#e71d85' : '#656565')};
  font-size: 17px;
  font-weight: 500;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
  flex: 0 0 auto;
  position: relative;
  transition: all 0.18s;

  &:first-child {
    padding-left: 40px;
  }

  &:last-child {
    padding-right: 40px;
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    bottom: 0;
    height: 1px;
    background-color: #e71d85;
    transition: all 0.3s ease;
  }

  &:after {
    left: 50%;
    width: ${props => (props.isActive ? '50%' : 0)};
  }

  &:before {
    right: 50%;
    width: ${props => (props.isActive ? '50%' : 0)};
  }

  &:hover {
    color: ${props => (props.isActive ? '#e71d85' : '#000')};
  }
`;

export default class MainCategories extends React.Component {
  componentDidMount() {}

  render() {
    const { pathname } = this.props;

    return (
      <Wrapper ref={el => (this.categoriesWrapper = el)}>
        <HorizontalScroll>
          {getMainCategories(this.props.categories).map(category => (
            <Category
              to={`/category/${parseRelativePathSlug(category.slug)}`}
              href={`/category/${parseRelativePathSlug(category.slug)}`}
              key={`detailMenuMain_${category.id}`}
              isActive={
                pathname
                  ? pathname
                    === `/category/${parseRelativePathSlug(category.slug)}`
                  : false
              }
              onClick={() => {
                if (window.ga) {
                  window.ga('send', {
                    hitType: 'event',
                    eventCategory: 'DetailMenu: Main Categories',
                    eventAction: 'click',
                    eventLabel: `${category.name} | ${parseRelativePathSlug(
                      category.slug,
                    )}`,
                  });
                }
              }}
              draggable={false}
            >
              {category.name}
            </Category>
          ))}
        </HorizontalScroll>
      </Wrapper>
    );
  }
}
