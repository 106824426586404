import React from 'react';
import styled from 'styled-components/macro';
import isEqual from 'lodash/isEqual';

import List from '../Menu/List';
import LinkWithIcon from '../../Header/LinkWithIcon';

const Wrapper = styled.div`
  display: initial;

  @media screen and (min-width: 1200px) {
    display: none;
  }
`;

const MainLinks = styled.div`
  padding: 20px 20px 30px;
`;

const MainLink = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default class MobileMainMenu extends React.Component {
  componentDidMount() {}

  shouldComponentUpdate(nextProps) {
    if (
      this.props.currentPath !== nextProps.currentPath
      || !isEqual(this.props.categories, nextProps.categories)
    ) {
      return true;
    }

    return false;
  }

  render() {
    return (
      <Wrapper
        ref={(el) => {
          if (el) {
            this.props.setScrollableContainer(el);
          }
        }}
      >
        <MainLinks>
          <MainLink>
            <LinkWithIcon
              to="/tickets"
              iconName="Tickets"
              iconWidth="23px"
              isActive={
                this.props.isVitismuseumOpen
                || this.props.currentPath === '/tickets'
              }
              iconMargin="0 7px 0 0"
              name="Посещение музея"
              marginRight={54}
              // onClick={(e) => {
              //   e.preventDefault();
              //   this.props.onChangePopup('visitmuseum');
              // }}
            />
          </MainLink>

          <MainLink>
            <LinkWithIcon
              to="/auth"
              isActive={this.props.isPersonalOpen}
              onClick={(e) => {
                e.preventDefault();
                this.props.onChangePopup('personal');
              }}
              name="Личный кабинет"
              iconName="Ingerprint"
              iconWidth="20px"
              iconMargin="0 7px 0 0"
            />
          </MainLink>
        </MainLinks>

        <List
          apiInstance={this.props.apiInstance}
          categories={this.props.categories}
          onClose={this.props.onClose}
          pathScheme={this.props.pathScheme}
          currentPath={this.props.currentPath}
        />
      </Wrapper>
    );
  }
}
