import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import LazyLoad from 'react-lazyload';
import Visibility from 'react-visibility-sensor';

import {
  decodeHtmlSpecialChars,
  hasOptionsDiscount,
  getMinimumDiscountOption,
  getMinQuantity,
} from '../../Utils';

import './slider.css';

import {
  Wrapper,
  PhotoWrapper,
  Slide,
  SlideInner,
  Photo,
  Gallery,
  ArrowLeft,
  ArrowRight,
  GalleryWrapper,
  Name,
  Price,
  TextSlider,
  AddToCart,
  Link,
  DiscountPrice,
  DiscountWrapper,
  DiscountPercent,
  DiscountText,
} from './styled';

class Card extends React.Component {
  slider = null;

  firstSee = false;

  componentDidMount() {}

  addToCart = (e) => {
    e.preventDefault();

    const productGA = this.createDataProductForGA();

    if (window.ga) {
      window.ga('ec:addProduct', productGA);

      window.ga('ec:setAction', 'add', {
        list: this.props.from,
      });

      window.ga('send', {
        hitType: 'event',
        eventCategory: 'Product', // this name for correct parse GA goals
        eventAction: 'add to cart',
        eventLabel: `from: ${this.props.from}`,
      });
    }

    window.dataLayer.push({
      ecommerce: {
        add: {
          products: [productGA],
        },
      },
    });

    if (this.props.onAddToCart) {
      this.props.onAddToCart();
    }

    const { card } = this.props;

    const { discount, discountPercent } = this.getDiscount();

    const product = {
      cartID: parseInt(this.props.id, 10),
      id: parseInt(this.props.id, 10),
      name: this.props.children,
      quantity: 1,
      maxquantity: getMinQuantity(card, []),
      price: this.props.price,
      discount,
      discountPercent,
      mainCategory: this.props.mainCategory,
      variant: '',
      weight: card.weight,
      width: card.width,
      length: card.length,
      height: card.height,
    };

    this.props.onAddCart(product);
  };

  nextSlide = (e) => {
    e.preventDefault();

    this.slider.slickNext();
  };

  prevSlide = (e) => {
    e.preventDefault();

    this.slider.slickPrev();
  };

  isInstockOptions = (options, productID) => {
    if (options && options.length > 0) {
      // if option have idproduct -- check instock only this product

      let instock = false;

      options.map((option) => {
        // check have link by idproduct in option
        const ownOption = option.values.filter(
          (optionValue) =>
            optionValue.idproduct &&
            parseInt(optionValue.idproduct, 10) === parseInt(productID, 10),
        );

        if (ownOption.length === 0) {
          // check all option values instock (if not all instock -- render no instock)

          const valuesInstock = option.values.filter(
            (optionValue) => parseInt(optionValue.instockquantity, 10) > 0,
          );

          instock = valuesInstock.length > 0; // if true -- instock, false -- render no instock
        }

        if (ownOption.length === 1) {
          instock = ownOption[0].instock > 0;
        }

        return false;
      });

      return instock;
    }

    return true;
  };

  generateTextSlider = ({
    price,
    startPrice,
    options,
    instock,
    discount,
    id,
  }) => {
    if (!instock || !this.isInstockOptions(options, id)) {
      return (
        <>
          <Price>Товар временно отсутствует</Price>

          <AddToCart>Посмотреть</AddToCart>
        </>
      );
    }

    const isDiscountInOptions = hasOptionsDiscount(options);

    if ((discount && discount > 0) || isDiscountInOptions) {
      if (isDiscountInOptions) {
        const minimumDiscountOption = getMinimumDiscountOption(options);

        return (
          <>
            <Price>
              <s>{`${minimumDiscountOption.price} ₽`}</s>
              <DiscountPrice>
                {`${minimumDiscountOption.discount} ₽`}
              </DiscountPrice>
            </Price>

            <AddToCart>Выбрать опции</AddToCart>
          </>
        );
      }

      return (
        <>
          <Price>
            <s>{`${price} ₽`}</s>
            <DiscountPrice>{`${discount} ₽`}</DiscountPrice>
          </Price>

          <AddToCart onClick={(e) => this.addToCart(e)} data-type="addtocart">
            Добавить в корзину
          </AddToCart>
        </>
      );
    }

    if (startPrice > 0 && options && options.length > 0) {
      let onePrice = false;

      options.map((option) => {
        const equalValuesByPrice = option.values.filter(
          (optionValue) =>
            parseInt(optionValue.price, 10) === parseInt(startPrice, 10),
        );

        onePrice = equalValuesByPrice.length === option.values.length;

        return true;
      });

      if (!onePrice) {
        return (
          <>
            <Price>{`от ${startPrice} ₽`}</Price>

            <AddToCart>Выбрать опции</AddToCart>
          </>
        );
      }

      return (
        <>
          <Price>{`${startPrice} ₽`}</Price>

          <AddToCart>Выбрать опции</AddToCart>
        </>
      );
    }

    return (
      <>
        <Price>{`${price} ₽`}</Price>

        <AddToCart onClick={(e) => this.addToCart(e)} data-type="addtocart">
          Добавить в корзину
        </AddToCart>
      </>
    );
  };

  createDataProductForGA = () => {
    let name = decodeHtmlSpecialChars(this.props.children);
    let price = this.props.startPrice || this.props.price;
    const { discount } = this.getDiscount();

    if (discount && discount > 0) {
      name = `СКИДКА: ${name}`;
      price = parseFloat(discount);
    }

    const product = {
      // Provide product details in an impressionFieldObject.
      id: this.props.id, // Product ID (string).
      name, // Product name (string).
      category: this.props.mainCategory, // Product category (string).
      list: this.props.from, // Product list (string).
      price,
    };

    return product;
  };

  onVisibility = (isVisible) => {
    if (this.firstSee === false && isVisible) {
      if (window.ga) {
        this.firstSee = true;

        const product = this.createDataProductForGA();

        window.ga('ec:addImpression', product);
      }
    }
  };

  renderDiscount = (discountPercent, discount) => {
    if (discountPercent && discount) {
      return (
        <></>
        // <DiscountWrapper>
        //   <DiscountPercent>{`${discountPercent}%`}</DiscountPercent>
        //   <DiscountText>скидка</DiscountText>
        // </DiscountWrapper>
      );
    }

    return '';
  };

  renderDesktopGallery = (size, gallery, sliderSettings) => (
    <Gallery size={size}>
      <ArrowLeft
        onClick={(e) => this.prevSlide(e)}
        isVisible={gallery.length > 1}
        data-type="arrow"
      />

      <Slider {...sliderSettings} ref={(slider) => (this.slider = slider)}>
        {gallery &&
          gallery.map((photo, id) => (
            <Slide>
              <SlideInner>
                <PhotoWrapper key={`galleryPhoto_id_${id}`}>
                  <Photo src={photo} />
                </PhotoWrapper>
              </SlideInner>
            </Slide>
          ))}
      </Slider>

      <ArrowRight
        onClick={(e) => this.nextSlide(e)}
        isVisible={gallery.length > 1}
        data-type="arrow"
      />
    </Gallery>
  );

  renderMobileGallery = (gallery) => (
    <PhotoWrapper>
      {/* <LazyLoad minHeight={100}> */}
      <Photo src={gallery[0]} />
      {/* </LazyLoad> */}
    </PhotoWrapper>
  );

  getDiscount = () => {
    let { discount, discountPercent } = this.props;

    if (hasOptionsDiscount(this.props.options)) {
      const minimumDiscountOption = getMinimumDiscountOption(
        this.props.options,
      );

      discount = minimumDiscountOption.discount;
      discountPercent = minimumDiscountOption.discountPercent;
    }

    return {
      discount,
      discountPercent,
    };
  };

  render() {
    const sliderSettings = {
      dots: false,
      arrows: false,
      className: 'cardSlider',
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: 'progressive',
    };

    // console.log(this.props, 'card');

    const { discount, discountPercent } = this.getDiscount();

    let { gallery } = this.props;

    const { typeRender } = this.props;

    if (this.props.card.mainimage) {
      gallery = [this.props.card.mainimage, ...this.props.gallery];
    }

    return (
      <Wrapper typeRender={typeRender}>
        <Visibility onChange={this.onVisibility} partialVisibility={false}>
          <Link
            href={`/product/${this.props.id}`}
            to={`/product/${this.props.id}`}
            onClick={(e) => {
              if (
                e.target.dataset.type !== 'arrow' &&
                e.target.dataset.type !== 'addtocart' &&
                window.ga
              ) {
                const product = this.createDataProductForGA();

                window.ga('ec:addProduct', product);

                window.ga('ec:setAction', 'click', {
                  list: this.props.from,
                });

                window.ga('send', {
                  hitType: 'event',
                  eventCategory: 'Product Card',
                  eventAction: 'click',
                  eventLabel: this.props.from,
                });

                if (this.props.onClickToViewProduct) {
                  this.props.onClickToViewProduct(
                    this.createDataProductForGA(),
                  );
                }
              }
            }}
            onMouseLeave={() => {
              if (this.slider) {
                this.slider.slickGoTo(0, false);
              }
            }}
          >
            {this.renderDiscount(discountPercent, discount)}

            <GalleryWrapper>
              {window.innerWidth > 1200
                ? this.renderDesktopGallery(
                    this.props.size,
                    gallery,
                    sliderSettings,
                  )
                : this.renderMobileGallery(gallery)}
            </GalleryWrapper>

            <Name>{decodeHtmlSpecialChars(this.props.children)}</Name>

            <TextSlider>{this.generateTextSlider(this.props)}</TextSlider>
          </Link>
        </Visibility>
      </Wrapper>
    );
  }
}

Card.defaultProps = {
  typeRender: 'default',
};

export default Card;
