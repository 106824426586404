import React from 'react';
import styled from 'styled-components/macro';
import * as Sentry from '@sentry/browser';

import {
  Button,
  Modal,
  MiniCheckbox,
  Input,
  Title4 as TitleOriginal,
} from '../UI';

import { validateEmail } from '../Utils';

const Wrapper = styled.div``;

const Title = styled(TitleOriginal)`
  text-align: center;
`;

const InputsWrapper = styled.div`
  max-width: 235px;
  margin: 0 auto;
  margin-top: 50px;
`;

const HiddenArea = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
`;

const CheckboxWrapper = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  margin-top: 50px;
`;

const LinkModal = styled.span`
  color: #e71d85;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
`;

export default class PersonalData extends React.Component {
  state = {
    email: '',
    name: '',
    openedFull: true,
    readPrivacy: false,
  };

  // shouldComponentUpdate(nextProps) {
  //   return true;
  // }

  onSubmit = () => {
    if (!this.state.email || !validateEmail(this.state.email)) {
      alert('Заполните поле Email');
      return false;
    }

    if (!this.state.name) {
      alert('Заполните поле Имя');
      return false;
    }

    const sendData = {
      email: this.state.email,
      name: this.state.name,
    };

    this.props.apiInstance
      .put('/subscription', sendData)
      .then(({ data }) => {
        if (data.result && Boolean(data.result)) {
          alert('На указанный Email отправлено подтверждение');
        }
      })
      .catch((error) => {
        let data = {};

        if (error.response) {
          data = error.response.data;
        }

        if (window.jivo_api) {
          window.jivo_api.open();
        }

        const sentryID = this.sendError(
          {
            sendData,
            errorResponse: error.response,
            errorCode: data.error || '',
            errorMessage: data.message || '',
          },
          'Subscribe Modal Fetch Error',
        );

        alert(
          `Ошибка #10013 | ${sentryID}. Напишите нам в чат и сообщите номера ошибок.`,
        );

        console.log(error);
      });
  };

  sendError = (extra, title) => {
    let eventID = false;

    Sentry.withScope((scope) => {
      scope.setExtras(extra);
      scope.setTag('page', 'subscribe_modal');
      scope.setTag('individual_errors', 'true');
      eventID = Sentry.captureEvent({
        message: title || 'Modal->Subscribe',
      });
    });

    return eventID;
  };

  openFull = () => {
    if (!this.state.openedFull) {
      this.setState({ openedFull: true });
    }
  };

  render() {
    const { isOpen, onClose, openingPoint } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        openingPoint={openingPoint}
        isCustomUpdateComponent
      >
        <>
          <Wrapper>
            <Title>Узнайте первыми о скидках и специальных предложениях</Title>

            <InputsWrapper>
              <Input
                label="Email"
                alignLabel="center"
                type="email"
                onChange={({ value }) => this.setState({ email: value })}
                onFocus={this.openFull}
              />

              <HiddenArea isOpen={this.state.openedFull}>
                <Input
                  label="Имя"
                  alignLabel="center"
                  onChange={({ value }) => this.setState({ name: value })}
                />
              </HiddenArea>
            </InputsWrapper>

            <CheckboxWrapper isOpen={this.state.openedFull}>
              <MiniCheckbox
                onClick={() => this.setState({ readPrivacy: !this.state.readPrivacy }, () => console.log(this.state, 'new state'))
                }
                isActive={this.state.readPrivacy}
              >
                Я прочел (прочла) условия
                {' '}
                <LinkModal
                  onClick={e => this.props.onChangeModal({
                    name: 'personalData',
                    openingPoint: {
                      x: e.clientX,
                      y: e.clientY,
                    },
                  })
                  }
                >
                  Обработки персональных данных
                </LinkModal>
              </MiniCheckbox>
            </CheckboxWrapper>

            <ButtonWrapper isOpen={this.state.openedFull}>
              <Button onClick={this.onSubmit}>Подписаться</Button>
            </ButtonWrapper>
          </Wrapper>

          {/* <PersonalDataModal
            isOpen={this.state.isOpenPrivacy}
            onClose={() => this.setState({ isOpenPrivacy: false })}
            plusIndex={1}
          /> */}
        </>
      </Modal>
    );
  }
}
