import styled, { css } from 'styled-components/macro';
import { Link as RLink } from 'react-router-dom';

export const CartAmount = styled.span`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #e71d85;
  color: #fff;
  font-size: 8px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -7px;
  transform: translate(0, -9px);
  transition: all 0.18s;

  ${(props) => (props.isDisabled
    ? css`
          opacity: 0;
        `
    : css`
          opacity: 1;
        `)}

  @media screen and (min-width: 1200px) {
    width: 15px;
    height: 15px;
    font-size: 9px;
  }
`;

export const Link = styled(RLink)`
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.18s;
  position: relative;

  @media screen and (min-width: 1200px) {
    font-size: 15px;

    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      bottom: -1px;
      height: 1px;
      width: 100%;
      background-color: #e71d85;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
    }
  }
`;

export const LinkWrap = styled.div`
  display: flex;
  margin-right: 25px;
  visibility: ${(props) => (props.isPopupOpen && (!props.isActive || props.hideOnActiveMobile)
    ? 'hidden'
    : 'visible')};
  opacity: ${(props) => (props.isPopupOpen && (!props.isActive || props.hideOnActiveMobile) ? 0 : 1)};
  transition: all 0.18s ease;

  &:last-child {
    margin-right: 0;
  }

  ${Link} {
    color: ${(props) => (props.isActive ? '#e71d85' : 'inherit')};

    &:hover {
      color: #e71d85;
    }

    &:after {
      visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
      opacity: ${(props) => (props.isActive ? 1 : 0)};
    }
  }

  @media screen and (min-width: 1200px) {
    visibility: visible;
    opacity: 1;
    margin-right: ${(props) => (props.marginRight ? `${props.marginRight}px` : '36px')};
  }
`;

export const Links = styled.div`
  display: none;
  flex-basis: 35%;
  justify-content: ${(props) => (props.left ? 'flex-start' : 'flex-end')};

  @media screen and (min-width: 1200px) {
    display: flex;
  }
`;
